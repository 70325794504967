import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

//Services
import { ErrorhandlerService } from './errorhandler.service';

//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentManagementService {
	private apiUrl = environment.apiUrl; 
	public adminCampaignsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	public adminCampaignObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	

  constructor( private http: HttpClient,
	private errorhandlerService: ErrorhandlerService){ 
  
  }
	
	
/* ------------------------------------------------------------ *\
	API CALLS
\* ------------------------------------------------------------ */

	pullCampaigns():Observable<any>{
		return this.http.get<any[]>(this.apiUrl + 'campaigns')
      .pipe(
        catchError(this.errorhandlerService.handleError())
      );
	}
	adminUpdateCampaign(id,json_data){
		return this.http.put<any[]>(this.apiUrl + 'campaign/'+ id, json_data).pipe(
		catchError(this.errorhandlerService.handleError())
		);
	}
/* ------------------------------------------------------------ *\
	FEED COMPONENTS
\* ------------------------------------------------------------ */
	
	getCampaigns(){
		var prtemp=this.processCampaigns();
		console.log('adminCampaignsObj', this.adminCampaignsObj);
		return this.adminCampaignsObj;
	}

  //Angular gets the accounts for the admin
	processCampaigns(){
		this.pullCampaigns().subscribe((response) => {
			console.log('mkck',typeof(response));
			
			var testinfo=JSON.parse(response);
			 this.adminCampaignsObj.next(testinfo);
			 console.log('responses', testinfo);
		});
	}
	
}
