import { Component, OnInit, Input } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {
@Input() CS: any | [];
   @Input() currentState: string;
   
  constructor(public CampSvc:CampaignService) { }

  ngOnInit(): void {
  }

}
