import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

//Services
import { ErrorhandlerService } from './errorhandler.service';

//Environment
import { environment } from '../../environments/environment';

//models
//import { Entry } from '../models/entry';

@Injectable({
  providedIn: 'root'
})
export class EntryService {
	submission_error=false;

  constructor(
    private http: HttpClient,
    private errorhandlerService: ErrorhandlerService) { }

    private apiUrl = environment.apiUrl;

    //Create a new User (Subscriber by default)
//	submitEntry(j){}
    submitEntry(entry): Observable<any> {
      //let body = JSON.stringify(entry);
      console.log('Submitting...');
      return this.http.post<any[]>(this.apiUrl + 'entry', entry)
        .pipe(
          map(response => response),
          catchError(this.errorhandlerService.handleError())
        );
    }
/*
	 factory.submitSweepsEntry = function(json_data){
 
    $http.post(window.config.api_host + 'entry', json_data).
    then(function (response){
	  factory.entry_status=response.status;
      if(response.status == 200){
        //$anchorScroll();
		factory.submission_error=false;
        console.log('Submission Received');
        factory.entry_success_object = angular.fromJson(response.data);
        factory.entry_success = true;
		ContentService.current_state='sweep_submitted';
        $analytics.eventTrack('Form Submission', {  category: 'Sweepstakes', label: 'entry' });
		
      }

    }).catch( function(response){
      if(response.status == 406){
        //console.log('Error in form');
        factory.entry_error = true;
        factory.error_response = angular.fromJson(response.data);
		console.log('406');
		console.log(factory.error_response);
      }
      else if(response.status == 409){
        //console.log('Error in bracket submission(duplicate)');
        factory.entry_error = true;
        factory.error_response = angular.fromJson(response.data);
		console.log('409');
		console.log(factory.error_response);
      }
      else if(response.status == 412){
        //console.log('Already enrolled in period);
        factory.entry_error = true;
        factory.error_response = angular.fromJson(response.data);
		factory.entry_conflict = true;
		ContentService.current_state='sweep_submitted';
		console.log('412');
		console.log(factory.error_response);
      }
      else if(response.status == 500){
        factory.entry_error = true;
        factory.error_response = 'There was an internal server issue. Please try again later.';
      }
      else{
        factory.entry_error = true;
        factory.error_response = 'There was an internal issue';
      }
    });
*/

}
