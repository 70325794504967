import { Component, OnInit, Input } from '@angular/core';
//Services
import { CampaignService } from '../../services/campaign.service';
import { EntryService } from '../../services/entry.service';



@Component({
  selector: 'app-score-sweeps',
  templateUrl: './score-sweeps.component.html',
  styleUrls: ['./score-sweeps.component.scss']
})
export class ScoreSweepsComponent implements OnInit {
@Input() CS: any | [];
   @Input() currentState: string;
	show_info=false;
	submitted=false;
	entryForm = {};
	form = {
		fullname:null,
		supervisoremail:null,
		employer:null,
		employeeid: null,
		supervisorname:null,
		email:null,
		phone:null,
		agree: false,
		campaign_id: null,
		subscribe:false
	};
   
  constructor(public CampSvc:CampaignService, public ES: EntryService) { }

  ngOnInit(): void {
	
  }
	togglePrize(){
		this.show_info=!this.show_info;
	}
	openSection(target){
	  if(this.CampSvc.currentState !='rules' && this.CampSvc.currentState !='privacy' ){
		  this.CampSvc.previousState=this.CampSvc.currentState;
	  }
	  //console.log('target is'+target+ 'prev state is' +  this.previous_state);
	  this.CampSvc.currentState=target;
	  this.scrollToElement(target);
  }
	scrollToElement(anchor): void {
		var element=document.getElementById(anchor);
		setTimeout(()=> { element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});}, 200);
	}
	submit_enrollment(enr){
		//console.log($scope.enroll_submitted);
		this.submitted=true;
		console.log(enr);
		console.log(this.form);
		console.log('---------Submitting New Enrollment--------');
		
		if(enr.valid && this.form.agree ==true ){
			this.form.campaign_id=this.CS.campaign_id;
			var json_data = [this.form];
			console.log('json is ', json_data);
			this.ES.submitEntry(json_data).subscribe(
				  (response) => {
					 if(response.status==412){
						 this.CampSvc.enrolled=true;
					 }
					 this.CampSvc.currentState="thanks";
				  }
			);
		}else{
			this.ES.submission_error=true;
			console.log('form dirty');
		}
		

	  }


}
