import { Component, OnInit, Input } from '@angular/core';
//Services
import { CampaignService } from '../../services/campaign.service';


@Component({
  selector: 'app-score-default',
  templateUrl: './score-default.component.html',
  styleUrls: ['./score-default.component.scss']
})
export class ScoreDefaultComponent implements OnInit {
   @Input() CS: any | [];
   @Input() currentState: string;
   

  constructor(public CampSvc:CampaignService) { }

  ngOnInit(): void {
  }

}
