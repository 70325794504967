import { Component, OnInit, Input } from '@angular/core';

import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	 @Input() CS: any | [];

  constructor(public CampSvc:CampaignService) { }

  ngOnInit(): void {
  }
  openSection(target){
	  if(this.CampSvc.currentState !='rules' && this.CampSvc.currentState !='privacy' ){
		  this.CampSvc.previousState=this.CampSvc.currentState;
	  }
	  console.log('target is'+target+ 'prev state is' +  this.CampSvc.previousState);
	  this.CampSvc.currentState=target;
	  this.scrollToElement(target);
  }

  closeSection(){
	  this.CampSvc.currentState=this.CampSvc.previousState;
	  this.scrollToElement(this.CampSvc.currentState);
  }
	scrollToElement(anchor): void {
		var element=document.getElementById(anchor);
		setTimeout(()=> { element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});}, 200);
	}
}
