
<div class="wrapper__background"  ></div><!-- /.wrapper__background-->

<div class="container" ng-controller="mainController">
	<main class="main__container" >
		<div class="main" id="landing">
			<section id="score_sweep" class="score score_sweep" >
				<div class="section-form">
					<div class="shell shell--large">
						<div class="section__inner">
							
							<div class="section__form">
								<div class="form" >
									<!--<form action="?" method="post">-->
									<form #entryForm="ngForm" name="entry_Form" novalidate autocomplete="off">
								
										<div class="form__head">
											<h2 class="h3">Complete your sweeps entry.</h2><!-- /.h3 -->

											<p>Submit your information below and you could be one of the lucky winners. </p>

										</div><!-- /.form__head -->

										<div class="form__body">
											<div class="form__row">
												<!--<div class="form__col">-->

													<div class="form__field" >
														<label for="fullname" class="form__label">Full Name</label>

														<div class="form__controls">
															<input type="text" 
																   class="field field--placeholder" 
																   name="full_name" 
																   id="full_name" 
																	
																   required>
														</div><!-- /.form__controls #fullname="ngModel"
																   [(ngModel)]="form.fullname"-->

													</div><!-- /.form__field -->

												<!--</div><!-- /.form__col -->

											</div><!-- /.form__row -->
										</div><!-- /.form__body -->

										<div class="form__actions">
											 <button  class="form__btn btn btn--red">
												<span>Submit</span>
											 </button>
											
										</div><!-- /.form__actions -->
									</form>
								</div><!-- /.form -->
							</div><!-- /.section__form -->
						</div><!-- /.section__inner -->
					</div><!-- /.shell -->
				</div><!-- /.section-form -->


			</section><!-- /.section score_sweep (entry)-->
			<p>campaign-admin works!</p>
		</div>
	</main>
</div>
