<app-popup [CS] = "campaign$"></app-popup>

<div class="wrapper__background" style="background-color: {{campaign$.bg_color}};background-image: url({{campaign$.campaign_header_image}});" [ngClass]="{'wrapper__background--dev': campaign$.themed=='1' }"></div><!-- /.wrapper__background-->
<app-partial-header></app-partial-header>

<div class="container" ng-controller="mainController">
	<main class="main__container" >
		<div class="main" id="landing">
			<app-landing [CS] = "campaign$" [currentState]="currentState$" class="stage" [ngClass]="{'currentstage' : CampSvc.currentState=='landing'}" *ngIf=" CampSvc.currentState=='landing'"></app-landing>
			
			
			<div  class="stage" *ngFor="let quiz of campaign$.quiz" [ngClass]="{'currentstage' : CampSvc.currentState=='quiz' || CampSvc.currentState=='video' }" >
				<app-video-content [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="quiz.content_source!==null && quiz.content_source!==''" [ngClass]="{'not_current' : CampSvc.currentState=='quiz'}"></app-video-content >
				<app-base-quiz  [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="campaign$.format_id==1" [ngClass]="{'not_current' : CampSvc.currentState=='video'}"></app-base-quiz>
				<app-bracket [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="campaign$.format_id==6"  [ngClass]="{'not_current' : CampSvc.currentState=='video'}"></app-bracket>
				<app-hth  [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="campaign$.format_id==3"  [ngClass]="{'not_current' : CampSvc.currentState=='video'}">hth is active</app-hth>
				<app-personality  [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="campaign$.format_id==4"  [ngClass]="{'not_current' : CampSvc.currentState=='video'}"></app-personality>
				<app-matching  [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="campaign$.format_id==7"  [ngClass]="{'not_current' : CampSvc.currentState=='video'}"></app-matching>
			</div>
			
			
			<app-score-default [CS] = "campaign$" [currentState]="currentState$" *ngIf="CampSvc.currentState=='score' && !campaign$.sweeps_active"></app-score-default>
			<app-score-sweeps [CS] = "campaign$" [currentState]="currentState$" *ngIf="CampSvc.currentState=='score' && campaign$.sweeps_active" ></app-score-sweeps>
			<app-thanks [CS] = "campaign$" [currentState]="currentState$" *ngIf="CampSvc.currentState=='thanks'" ></app-thanks><!--
			State is: {{CampSvc.currentState}} and Sweeps is {{campaign$.sweeps_active}}-->
			<app-footer [CS] = "campaign$"></app-footer>
		</div>
	</main>
</div>
