import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { ContainerComponent } from './containers/container.component';
import { CampaignAdminComponent } from './components/admin/campaign-admin.component';
import { CampaignEntryComponent } from './components/admin/campaign-entry.component';



const routes: Routes = [
	{path:'sweeps/:id', redirectTo: 'home/:id/1'},
	{path:'sweeps', redirectTo: 'home/0/1'},
	{path:'home/:id/:swp', component: ContainerComponent},
	{path:'home/:id', redirectTo: 'home/:id/0'},
	{path:'admin', component: CampaignAdminComponent},
	{path:'campaign-detail/:id', component: CampaignEntryComponent},
	{path:'campaign-detail', component: CampaignEntryComponent},
	{path:'*/:id', redirectTo: 'home/:id/0'},
	{path:'**', redirectTo: 'home/0/0'}
	
	
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
	
}




