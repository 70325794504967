<header class="header">
	<div class="shell shell--large">
		<div class="header__inner">
			<a href="#" class="logo header__logo">
				<img
					src="assets/images/temp/logo.png" 
					srcset="assets/images/temp/logo.png 1x, assets/images/temp/logo@2x.png 2x"
					alt=""
				>
			</a>

			<p>Activity</p>
		</div><!-- /.header__inner -->
	</div><!-- /.shell -->
</header><!-- /.header -->