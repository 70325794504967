<div class="article">
	<div class="shell shell--large">
		<div class="article__inner">
			<div class="article__actions">
					<a (click)="closeSection()" class="btn">Go Back</a>
				</div><!-- /.article__actions -->
				
			<div class="article__head">
				<h1 class="h2">TERMS OF USE</h1><!-- /.h2 -->
				<p>REVISED 12/12/19</p>
			</div><!-- /.article__head -->

			<div class="article__body js-container simplebar">
<p>IMPORTANT! PLEASE READ THESE TERMS OF USE (<strong>"TERMS OF USE"</strong>) CAREFULLY PRIOR TO USING THE STARZ
  SUBSCRIPTION SERVICE (<strong>"SERVICE"</strong>). These terms of use are a legally binding agreement between STARZ
  ENTERTAINMENT, LLC (<strong>"Starz," "Us"</strong> or <strong>"We"</strong>) and you concerning the Service; (2)
  website located At starz.com associated with the Services ASSOCIATED WITH THE SERVICE ("SITE"); AND (3) interactive
  features or downloads that are made available through or interact WITH the Service or SITE
  (<strong>"FEATURES"</strong>); AND (4) Content (as the same is defined in Section 14.1 below). By USsING THE SERVICE,
  SITE, AND FEATURES YOU ARE UNCONDITIONALLY AGREEING TO THE TERMS OF USE. IF YOU DO NOT AGREE TO THE TERMS OF USE,
  PLEASE DO NOT USE THE SERVICE OR THE SITE. </p>
<p>Please note that throughout these Terms of Use, you will see capitalized words or phrases. These capitalized words
  and phrases are defined terms. The defined terms are first set off from the other text in parentheses. Their
  definitions are found either in these Terms of Use or in our Privacy Policy, found here:
  http://www.starz.com/privacypolicy.</p>
<p>1. ACCESS; USE. In order to use and access the Service, Site, and Features you must meet the following access
  requirements (<strong>"Access Requirements"</strong>): (1) be 18 years of age or older (or whatever the applicable age
  of majority (legal adulthood) is where you are located); (2) you must reside within the fifty states of the United
  States of America (<strong>"U.S."</strong>), the District of Columbia, and certain US territories (<strong>"Service
    Area"</strong>); (3) you must be a subscriber to the Service through Starz or an authorized Service provider
  (<strong>"Distributor"</strong>); and (4) if you subscribe through a Distributor you must activate your subscription
  and establish a registered profile with Starz tied to the Service (your profile together with your Subscription will
  establish your user account) (<strong>"User Account"</strong>). </p>
<p>In certain instances, Starz may allow you to use and access certain Service, Site, Features, and Content (e.g. "Free"
  Content) without requiring that you meet Access Requirements nos. 3 and/or 4 set forth above. Regardless of whether
  you meet all of the Access Requirements set forth above, by using and accessing any Service, Site, Features and
  Content, you are unconditionally agreeing to these Terms of Use. Notwithstanding the foregoing, by using the Site or
  the Services, you represent, acknowledge and agree that you are at least 18 years of age.</p>
<p>2. GRANT OF RIGHTS; RESTRICTIONS. Starz hereby grants you a limited, non-exclusive, non-transferable license to
  access, use and privately display the Service, Site, Content and Features, solely within the Service Area, in
  accordance with these Terms of Use. Starz grants you this limited license for your personal use only, and not for any
  commercial purpose. The use of the Service, Site, Content or Features for commercial purposes is unauthorized and may
  constitute infringement. This license does not include any rights to: (1) sell, resell, lend, lease, rent or otherwise
  commercially use the Service, Site, Content or Features or any other material or information contained therein; (2)
  collect or use any information, data, listings, or descriptions for any reason; (3) create derivative works from the
  Service, Site, Features or Content or any other materials or information contained therein; (4) download or copy User
  Account information of any third party; (5) use data mining, robots, or other data gathering and extraction tools; (6)
  distribute, transfer, or post any Content on any third party website, online service or other media; (7) modify,
  adapt, create derivative works from or translate any part of the Service, Site, Content or Features; (8) reverse
  engineer, decompile or disassemble the Service, Site, Content or Features, or otherwise attempt to obtain access to
  the Starz IP (as defined in Section 14); (9) remove or alter any copyright, trademark or other proprietary notice
  contained in the Service, Site, Content or Features. Except as provided herein, you may not frame or utilize framing
  techniques to use, surround or enclose the Service names, Content or Features or any trademarks, service marks, logo,
  or other proprietary information (including: images, text, page layout, or form) of Starz and/or its affiliates or
  subsidiaries or Content providers without Starz' express written consent. You may not use any meta-tags or any other
  "hidden text" utilizing the name of the Service, Site, Content, Features, Starz and/or its affiliates, subsidiaries or
  Content providers, or any trade names or trademarks or other copyrighted materials relating thereto, without the
  express written consent of Starz.</p>
<p>Starz may control the maximum number of simultaneous streams per User Account (across all Content on the Service)
  regardless of the number of devices on which the Service is available. Starz may change the maximum number of
  simultaneous streams and/or registered devices per User Account that you may use at any time, in its sole discretion.
</p>
<p>3. USER ACCOUNTS. In order to use the Service, you will be required to create a User Account. You may only open a
  User Account for yourself, and not for any other person. A User Account can only be established by consumers that meet
  the Access Requirements. You are responsible for all activity occurring under your User Account, including maintaining
  the confidentiality of each username and password. You agree that you will: (a) immediately notify Starz of any
  unauthorized use of your User Account; and (b) not allow any third party to use your User Account. Starz will have no
  liability for any loss or damage arising from use or misuse of your User Account or your failure to comply with these
  requirements. Starz, its affiliates and its third party licensors cannot and will not be liable for any loss or damage
  arising from any failure on the part of any person to comply with these obligations. The Service, Site and/or Features
  may provide you with the opportunity to connect and/or link your User Account to certain third party social media
  sites (<strong>"Social Media Sites"</strong>). If you choose to connect or link your User Account to any Social Media
  Site via the Service, Site or Features you consent to the release of information about you to others, including to the
  Social Media Site(s). If you do not want information about you to be shared in this manner, do not connect or link
  your User Account to any Social Media Site(s).</p>
<p>4. PAYMENT; PRICING; CANCELLATION; PROMOTIONAL OFFERS.</p>
<p>4.1 Payment: To access and subscribe to the Service you will need to create an account with Starz or with a
  Distributor and provide a credit card or other payment method accepted by Starz or the Distributor (<strong>"Payment
    Method"</strong>). By providing the Payment Method, you represent and warrant that you are authorized to use such
  Payment Method. The Payment Method and other billing information that you submit (<strong>"Personal Billing
    Information"</strong>) is a subset of your User Account information. Your Personal Billing Information may be
  collected and processed by an independent third party selected by, but not affiliated with, Starz (<strong>"Payment
    Processor"</strong>). The use of your Personal Billing Information will be used by Starz in accordance with Starz’s
  Privacy Policy, but the use of your Personal Billing Information by any Payment Processor will be governed by such
  Payment Processor’s own independent privacy policy, and IN THE EVENT THE PAYMENT PROCESSOR FAILS TO COMPLY WITH ITS
  OWN PRIVACY POLICY OR EXPERIENCES A DATA BREACH THAT AFFECTS ANY OF YOUR PERSONAL BILLING INFORMATION OR OTHER DATA,
  STARZ WILL IN NO WAY BE RESPONSIBLE OR LIABLE TO YOU FOR SUCH ACTIVITY. BY ESTABLISHING A PAYMENT METHOD WITH STARZ OR
  DISTRIBUTOR, YOU AGREE TO PAY THE SUBSCRIPTION FEE ON A DESIGNATED PERIODIC CYCLE TO ACCESS THE SERVICE
  (<strong>"SUBSCRIPTION FEE"</strong>) ALONG WITH ANY APPLICABLE TAXES. THE SUBSCRIPTION FEE WILL BE BILLED AT THE TIME
  YOU ESTABLISH YOUR SUBSCRIPTION (EXCEPT IN THE CASE OF A TRIAL SUBSCRIPTION) AND ON AN ONGOING, REGULAR BASIS BASED ON
  THE PLAN SELECTED AT THE TIME OF SUBSCRIPTION (E.G. MONTHLY, ANNUAL, QUARTERLY ETC.) UNLESS YOU CANCEL YOUR
  SUBSCRIPTION. ALL PAYMENTS ARE PROCESSED AND BILLING CYCLES DETERMINED BY STARZ OR APPLICABLE DISTRIBUTOR. Please
  carefully review the payment and billing policies prior to establishing your Subscription. It is your responsibility
  to update and maintain the Payment Method associated with your Subscription and all changes to your Payment Method
  must be processed through Payment Processor or your Distributor. In the event you fail to timely pay the Subscription
  Fee, each of Distributor and Starz reserve the right to suspend your User Account and access to the Service, until
  such time that all Subscription Fees due have been paid.</p>
<p>4.2 Pricing: Subscription pricing for the Service may vary and Starz or Distributor may change the price for a
  Subscription to the Service at any time subject to Starz and such Distributor’s policies. Any changes to pricing will
  be communicated to Subscribers at least 30 days before the change and if the price change is not acceptable to
  Subscriber, the subscription may be cancelled.</p>
<p>4.3 Cancellation: You may cancel your subscription to the Service through Starz or the applicable based Distributor
  based on how you subscribed. For details on how to cancel your subscription, please visit the “Cancel” section of the
  FAQs posted at starz.com or in “Settings” in the Service. If you cancel your subscription, the cancellation will go
  into effect at the end of your then-current paid subscription period. You acknowledge and agree that cancellation of
  your subscription is your sole recourse if you have any dissatisfaction, issue or concern related to the Service,
  Site, Content or Features.</p>
<p>4.4 Refunds: WE DO NOT PROVIDE CREDIT, REFUNDS, OR PRORATED BILLING FOR SUBSCRIPTIONS THAT ARE CANCELLED. However,
  you will continue to have access to the service through the end of your current billing period. Starz and each
  Distributor reserve the right to offer refunds, discounts or other consideration in select circumstances at its sole
  discretion. </p>
<p>4.5 Promotional Offers: The Service may be made available to you on a free trial basis for a limited time
  (<strong>"Trial Subscription"</strong>). Starz and Distributors are under no obligation to offer a Trial Subscription
  and each, reserves the right, in its absolute discretion, to determine the timing, availability, and eligibility for a
  Trial Subscription. The terms and conditions of each Trial Subscription will be set forth in the marketing materials
  accompanying the Trial Subscription offer. Unless otherwise specified in the terms and conditions of a Trial
  Subscription offer you are limited to one: (1) Trial Subscription, and the determination of whether you have used a
  Trial Subscription may be done by Starz or Distributor, each in its sole discretion. When you subscribe to a Trial
  Subscription, you must provide Starz or Distributor with a valid Payment Method as described in Section 4.1 above.
  Your Payment Method will not be charged by Starz or Distributor during the Trial Subscription period. IF YOU DO NOT
  CANCEL THE TRIAL SUBSCRIPTION PRIOR TO ITS EXPIRATION, THE SUBSCRIPTION FEE FOR THE SERVICE WILL BE CHARGED TO YOUR
  PAYMENT METHOD WHEN THE TRIAL SUBSCRIPTION EXPIRES. YOU AGREE AND ACKNOWLEDGE THAT NEITHER STARZ NOR DISTRIBUTOR IS
  OBLIGATED TO NOTIFY YOU THAT YOUR TRIAL SUBSCRIPTION WILL END OR HAS ENDED. IF YOU WISH TO AVOID CHARGES TO YOUR
  PAYMENT METHOD, YOU MUST CANCEL YOUR SUBSCRIPTION PRIOR TO MIDNIGHT EASTERN TIME ON THE LAST DAY OF YOUR FREE TRIAL
  PERIOD. To find additional details about your Trial Subscription, including the date the Trial Subscription ends,
  please visit “Settings” in the Service if you subscribed via starz.com or review your account information provided by
  your Distributor if you subscribed through a Distributor. As a condition of these Terms of Use, you agree not to
  register multiple User Accounts for the same promotional offer using multiple names, addresses, email addresses or
  other means. Any such action will constitute a violation of these Terms of Use and may result in the termination of
  your User Account.</p>
<p> 5. PARENTAL CONTROLS; CONTENT. MOST CONTENT AVAILABLE ON THE SERVICE, SITE AND FEATURES IS RATED ACCORDING TO
  GUIDELINES ESTABLISHED BY THE MOTION PICTURE ASSOCIATION OF AMERICA (I.E., G, PG, PG-13, R) OR TELEVISION
  RATINGS/CONTENT ADVISORIES. HOWEVER, SOME CONTENT MAY NOT BE RATED, AND MAY CONTAIN GRAPHIC VIOLENCE, SEXUAL CONTENT
  AND MATURE THEMES. SUCH CONTENT MAY NOT BE SUITABLE FOR ALL VIEWERS. </p>
<p>5.1 Parental Controls: Parental controls (<strong>"Parental Controls"</strong>) for the Service may be accessed in
  one of the two following ways: (a) if your Distributor passes through your Parental Control settings as part of the
  authentication process for the Service, Parental Controls and any modifications thereto may be managed via your
  Distributor; or (b) you will be able to establish Parental Control settings during the User Account creation process
  or by accessing your User Account. Parental Controls allow you to set a maximum movie rating and television rating for
  the User Account. Please note that changes made to Parental Controls may not take effect immediately, and during some
  periods there could be a delay before your new settings become effective.</p>
<p>If you access any adult or mature content on the Service, you acknowledge and agree that you may be exposed to visual
  images and sounds that depict nudity and sexual activity or are otherwise sexual, erotic or graphic in nature and may
  include violence and adult language. You agree to the following, irrespective of whether or not the Service include
  Parental Control functions, and irrespective of the extent to which you use such Parental Control functions: (1) it is
  your responsibility to determine which Content meets your standards; and (2) under no circumstances will Starz or its
  Content providers be liable in any way for any Content that you have access to during use of the Service, including,
  without limitation, any errors or omissions in any Content, quality of the Content, availability of the Content, the
  subject matter of the Content or any loss or damage of any kind incurred as a result of your use of the Content; and
  (3) you acknowledge and agree that any adult-oriented material you access is being retrieved from a location where it
  does not violate any federal, state, or local obscenity law or community standard, and agree to bear the full,
  complete, and sole responsibility for bringing such material into whatever community you choose to do so. </p>
<p>5.2 Content: It is your responsibility to review and monitor the access restrictions associated with each piece of
  Content available via the Service, and Starz and its Distributors accept no liability for any access restrictions or
  the expiration of any availability windows. Starz reserves the right, in its sole discretion, to disable access to any
  Content at any time and makes no warranties that the access restrictions specified for each piece of Content will
  always be complete, current, or accurate.</p>
<p>6. SERVICE UPDATES; SOFTWARE. BY USING THE SERVICE, YOU ACKNOWLEDGE AND AGREE TO RECEIVE, WITHOUT FURTHER NOTICE OR
  PROMPTING, UPDATED VERSIONS OF THE SERVICE AND RELATED THIRD-PARTY SOFTWARE. IF YOU DO NOT ACCEPT THE FOREGOING TERMS,
  DO NOT USE THE SERVICE.</p>
<p>7. tvOS SUBSCRIBERS. If you access the Service via a tvOS product such access and use of the Service must comply with
  the App Store Terms of Service in addition to these Terms of Use. You acknowledge and agree that Apple, Inc.,
  ("Apple") and any subsidiaries of Apple, are third party beneficiaries to these Terms of Use. Upon your acceptance of
  these Terms of Use, Apple will have the right (and be deemed to have accepted the right) to enforce these Terms of Use
  as a third party beneficiary. </p>
<p> </p>
<p>8. TERMINATION; SUSPENSION. You may terminate your use of the Service, Site and Features at any time. Your right to
  use the Service, Site, Content and Features will automatically terminate if you fail to comply with any provision of
  the Terms of Use and Starz and its Distributors, each reserve the right to cancel, terminate or restrict your access
  to the Service, Site, Content and Features, or any portion thereof, at any time, without notification, for any or no
  reason whatsoever. In the event of termination you are responsible for all Subscription Fees and any applicable tax
  and service fees incurred prior to the effective date of termination. </p>
<p>9. CUSTOMER SERVICE.</p>
<p>9.1 Service; Site and Features: If you need assistance with the Service, Site, Content or Features and are unable to
  resolve your question via the Help feature of the Service, please contact us at: Starz Entertainment, LLC, 8900
  Liberty Circle, Englewood, CO 80112, Attn: Manager Customer Care; email help@starz.com or via phone at 855.247.9175.
</p>
<p>9.2 Subscription Fee and Payment Method: For questions related to your Subscription Fee, Payment Method or other
  matters relating to billing, please contact your Distributor or if you subscribed directly you can email us at <a href="mailto:help@starz.com">help@starz.com</a> or call us at 855.247.9175.</p>
<p>10. PRIVACY POLICY. Starz may collect and use certain personally identifiable information and non-personally
  identifiable information about you in connection with your use of the Service, Site, Content or Features, and such
  collection and use of personally identifiable information is subject to the Starz Privacy Policy available at
  http://www.starz.com/privacypolicy. </p>
<p>11. POLICIES FOR INDIVIDUALS UNDER THE AGE OF 13. Starz encourages the responsible use of the internet by children
  together with their parents and to that end, Starz does not knowingly collect or use any personally identifiable
  information submitted or posted to the Service, Site and/or Features from individuals under the age of 13, except in
  order to respond on a one-time basis to a specific request for information. </p>
<p>12. USER SUBMISSIONS. Starz is pleased to hear from its customers and welcomes your comments regarding our products
  and services. These comments should only be submitted to one of the mailboxes available to users of the Service and
  Site provided from time to time for that purpose. All remarks, suggestions, ideas, graphics, or other information
  communicated to Starz through the Service and/or Site (each, a <strong>"Submission"</strong>) will forever be the
  property of Starz. Starz will not be required to treat any Submission as confidential, and will not be liable for any
  ideas for its business (including without limitation, product or advertising ideas) and will not incur any liability
  as a result of any similarities between your Submission and any future Starz products, services or operations. Without
  limitation, Starz will have exclusive ownership of all present and future existing rights to the Submissions of every
  kind and nature, everywhere. Starz will be entitled to use the Submission for any commercial or other purpose
  whatsoever, without compensation to you or any other person sending the Submission. You acknowledge that you are
  responsible for whatever material you submit, and you, not Starz, have full responsibility for the message, including
  its legality, reliability, appropriateness, originality, and copyright. You acknowledge and agree that neither Starz
  nor any of its licensees, successors or assigns has now, or will have in the future, any duty or liability, direct or
  indirect, vicarious, contributory or otherwise, with respect to the infringement or protection of any copyright in and
  to your Submission, and that Starz and its licensees, successors or assigns are not responsible for the loss,
  deletion, failure to store, or misdelivery of any feedback submitted.</p>
<p>Starz generally does not pre-screen, verify or edit your Submissions. However, Starz and its agents reserve the right
  to investigate any violation of the Terms of Use and to take appropriate remedial action. Starz has absolute
  discretion to enforce the Terms of Use, including, but not limited to warning users of violations, disabling or
  suspending access, deleting, screening or editing any Submission, or prohibiting any behavior that does not comply
  with the Terms of Use. You are entirely responsible and liable for all activities conducted by you and any user of
  your User Account, including the transmission, posting, or other provision of text, files, links, software,
  photographs, video, sound, music or other information or material to any portion of the Service, Site or Features.</p>
<p>13. CONFIDENTIAL AND PROPRIETARY INFORMATION. You agree not to try to access and not to access any information
  through the Service, Site or Features that does not pertain to you and which you are not properly authorized to
  receive. You agree that if you should access information about another individual or entity, or otherwise receive
  information you are not authorized to receive, or if you become aware that someone else has accessed or attempted to
  access information that person was not authorized to receive, you will immediately cease such unauthorized access,
  notify Starz of the unauthorized access, and reasonably assist Starz in identifying and correcting the circumstances
  that permitted such unauthorized access. You will keep confidential all information you receive concerning another
  individual or entity, will not use such information, will return such information to Starz immediately, will not
  transmit it to any other person or entity and will not reproduce or distribute it in any electronic or other form.
</p>
<p>14. INTELLECTUAL PROPERTY.</p>
<p>14.1 As between you and Starz, the Service, Site, Content and Features are owned by Starz and/or its licensors and
  content and technology providers, and are protected by applicable domestic and international copyright laws. As used
  herein, <strong>"Content"</strong> means: (1) any and all motion pictures, images, animations, artwork, copy or
  information; and (2) any and all other human readable audio and/or visual elements, regardless of the form or format,
  accessible via the Service, Site or Features. Any and all underlying patentable or copyrightable elements (or elements
  protectable as trade secrets or otherwise under intellectual property laws) related to the Service, Site, Content
  and/or Features, including without limitation, source code, script, object code, software, protocols, algorithms,
  computer programs, data and other sets of statements and instructions contained in the Service, Site, Content and/or
  Features will be considered the property of Starz (<strong>"Starz IP"</strong>). All rights not granted in these Terms
  of Use are reserved, and there are no implied licenses. </p>
<p>14.2 STARZ, ENCORE and all related registered and unregistered trademarks and service marks (<strong>"Starz
    Marks"</strong>) are the property of Starz Entertainment, LLC. Certain characters, logos, names and images
  incorporated by Starz Entertainment, LLC on the Service, Site, Content and Features are also protected as registered
  or unregistered trademarks, trade names and/or service marks and are the property of their respective owners. The
  Starz Marks may not be used without the express written permission of Starz. </p>
<p>15. REPRESENTATIONS AND WARRANTIES. By accessing or using the Service, Site or Features you represent and warrant
  that: (a) you are a resident of the Service Area; (b) that you are a subscriber in good standing through Starz or a
  Distributor of the Service; (c) that you have legal capacity to enter into this agreement (i.e., that you are at least
  eighteen (18) years old or of sufficient age to be considered an adult in your state of residence and possess
  sufficient mental capacity and are otherwise entitled to be legally bound in contract); (d) the information you
  provide during registration for a User Account, and any other communications with Starz and/or in connection with the
  Service for any reason, is true, correct, complete, and current and you agree to update such information as necessary
  so that it remains current, accurate, and complete; and (e) you have read and agree to abide and be bound by these
  Terms of Use.</p>
<p>16. FORWARD LOOKING STATEMENTS. The Service, Site or Features may contain forward-looking statements regarding
  Starz's business, customers, partners, future products, and services or other factors that may affect future earnings
  or financial results. Such statements involve risks and uncertainties, which could cause actual results to vary
  materially from those expressed in or indicated by the forward-looking statements. Examples of these risks include
  delays in development, competitive service, and product offerings, and lack of market acceptance.</p>
<p>17. EXPORT RESTRICTIONS. You agree that you will comply with all applicable export laws, restrictions and regulations
  of the U.S. Department of Commerce, the U.S. Department of Treasury and any other U.S. or foreign agency or authority.
  You agree not to export or re-export, or allow the export or re-export of the Service in violation of any such law,
  restriction or regulation.</p>
<p>18. LINKS TO AND FROM THIRD PARTY SITES.</p>
<p>18.1 The Service, Site, Content and Features contain or may contain links to other internet sites, including without
  limitation Social Media Sites. Starz does not verify, warrant, endorse or take responsibility for the availability,
  accuracy, completeness, quality, practices or policies of such sites or content available from such sites. Starz does
  not make any representations or warranties as to the security of any information (including, without limitation,
  credit card and other personal information) you might be requested to give any third party, and you irrevocably waive
  any claim against us with respect to such sites.</p>
<p>18.2 Access to the Service through any third party service is subject to the rules, policies and guidelines of such
  third party service.</p>
<p>18.3 You are not permitted to link to the Service, Site, Content or Features from any third-party site without the
  prior written permission of Starz.</p>
<p>19. ASSIGNMENT. You may not assign your rights and obligations under the Terms of Use for any reason. Starz may
  assign its rights and obligations under the Terms of Use at any time. </p>
<p>20. DISCLAIMER OF WARRANTIES. YOU EXPRESSLY AGREE THAT USE OF THE SERVICE, SITE, CONTENT AND FEATURES IS AT YOUR SOLE
  RISK. NEITHER STARZ, ITS DISTRIBUTORS, PARENTS, AFFILIATES, EMPLOYEES, AGENTS, SERVICE PROVIDERS, THIRD PARTY
  INFORMATION PROVIDERS, LICENSORS OR THE LIKE, WARRANT THAT THE SERVICE, SITE, CONTENT AND/OR FEATURES WILL BE
  UNINTERRUPTED, SECURE OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE
  OF THE SERVICE, SITE, CONTENT OR FEATURES, OR AS TO THE ACCURACY, RELIABILITY, SECURITY OR CONTENT OF ANY INFORMATION
  OR SERVICE CONTAINED IN OR PROVIDED THROUGH THE SERVICE, SITE, CONTENT OR FEATURES. THE SERVICE, SITE, CONTENT AND
  FEATURES, ALL CONTENT AND INFORMATION PROVIDED THEREIN, AND ALL ELEMENTS OF ANY SOFTWARE RELATED THERETO ARE PROVIDED
  ON AN "AS IS," "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, AND ALL SUCH WARRANTIES, WHETHER ORAL OR WRITTEN,
  EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTIBILITY OR FITNESS
  FOR A PARTICULAR PURPOSE ARE HEREBY DISCLAIMED AND EXPRESSLY NEGATED. NO ORAL ADVICE OR WRITTEN INFORMATION GIVEN BY
  STARZ, ANY OF ITS PARENTS, AFFILIATES, EMPLOYEES, AGENTS, SERVICE PROVIDERS, THIRD PARTY INFORMATION PROVIDERS,
  LICENSORS OR THE LIKE, WILL CREATE A WARRANTY; AND YOU WILL NOT RELY ON ANY SUCH INFORMATION OR ADVICE. THE ABOVE
  EXCLUSION OF IMPLIED WARRANTIES WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.</p>
<p>21. LIMITATION OF LIABILITY. UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE, WILL STARZ, ITS DISTRIBUTORS, PARENTS,
  AFFILIATES, EMPLOYEES, AGENTS, SERVICE PROVIDERS, THIRD PARTY INFORMATION PROVIDERS, LICENSORS, OR ANYONE ELSE
  INVOLVED IN CREATING, PRODUCING OR DISTRIBUTING THE SERVICE, SITE, CONTENT OR FEATURES BE LIABLE FOR ANY DIRECT,
  INDIRECT, INCIDENTAL, EXEMPLARY, ECONOMIC, PUNITIVE, LOST PROFIT, SPECIAL OR CONSEQUENTIAL DAMAGES OR FOR ANY DAMAGES
  THAT RESULT FROM OR ARE RELATED TO THE USE OF OR INABILITY TO USE THE SERVICE, SITE, CONTENT OR FEATURES; OR THAT
  RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES, ERRORS, DEFECTS, DELAYS IN OPERATION, OR
  TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT LIMITED TO AN EVENT OF FORCE MAJEURE. THE ABOVE
  LIMITATIONS OF LIABLITY WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW. YOU ASSUME THE RISK IN USING
  THE SERVICE, SITE AND/OR FEATURES AS WELL AS RESPONSIBILITY FOR ESTABLISHING PROCEDURES FOR DATA BACKUP AND VIRUS
  CHECKING AS YOU CONSIDER NECESSARY.</p>
<p>22. INDEMNIFICATION. You agree to indemnify and hold harmless Starz, its parents, subsidiaries, affiliates, partners,
  agents, and licensors and each of their respective officers, directors, shareholders and employees against and from
  any and all liabilities, claims, costs, damages, reasonable settlements and expenses (including without limitation
  reasonable attorneys’ fees and court costs) to the extent arising out of or attributable to your breach of any of the
  provisions, representations or warranties set forth in these Terms of Use, and/or from your placement or transmission
  of any content onto the Service servers, and/or from any and all use of your User Account in violation of the Terms of
  Use or the failure to fulfill any obligations relating to your User Account incurred by you or any other person using
  your User Account. We reserve the right to take over the exclusive defense of any claim for which we are entitled to
  indemnification under this Section.</p>
<p>23. Force Majeure. Starz will not have any liability to you for any delay or non-performance of its obligations under
  the Terms of Use, to the extent that such delay or non-performance is attributable to an event of Event of Force
  Majeure (as hereinafter defined). For the purposes of the Terms of Use, "Event of Force Majeure" will mean an act of
  God, labor dispute, or failure of facilities, networks, equipment or software, or any similar event, to the extent
  that such event is beyond the reasonable control of Starz.</p>
<p>24. MODIFICATIONS TO THE TERMS OF USE. Starz reserves the right, at its sole discretion, to change, modify,
  discontinue, enhance, add or remove any portion of the Service, Site, Content and Features, or any provision of the
  Terms of Use, in whole or in part, at any time. If we make a material change to the Terms of Use, we will notify you
  by posting a notice on the Site. If you are a Subscriber, we will also send an email to the email address you most
  recently provided to us prior to the material change taking effect. Any material change to these Terms of Use will be
  effective automatically 30 days after the revised Terms of Use are first posted or, for users who register or
  otherwise provide opt-in consent during this 30-day period, at the time of registration or consent, as applicable. The
  date that these Terms of Use were last modified can be found at the end of these Terms of Use. </p>
<p>25. COPYRIGHT NOTICE. If you believe that any Content appearing on the Service has been copied in a way that
  constitutes copyright infringement under the laws of the United States, please forward the following information to
  the Copyright Agent named below:</p>
<p>• Your name, address, telephone number, and email address;</p>
<p>• A description of the copyrighted work that you claim has been infringed;</p>
<p>• The exact URL or a description of where the alleged infringing material is located;</p>
<p>• A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright
  owner, its agent, or the law;</p>
<p>• An electronic or physical signature of the person authorized to act on behalf of the owner of the copyright
  interest; and</p>
<p>• A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that
  you are the copyright owner or authorized to act on the copyright owner’s behalf.</p>
<p>Copyright Agent</p>
<p>Starz Entertainment, LLC</p>
<p>8900 Liberty Circle</p>
<p>Englewood, CO 80112</p>
<p>Attn: Business &amp; Legal Affairs</p>
<p>720.852.4100</p>
<p>business.affairs@starz.com</p>
<p>Please note that while Starz seeks to preserve any and all exemptions from liability that may be available under the
  copyright law, this is not a stipulation that Starz is a service provider as defined in 17 USC section 512c or
  elsewhere in the law.</p>
<p>26. ELECTRONIC COMMUNICATIONS. When you use the Service, Site, Content and Features, or send emails, text messages,
  and other communications from your desktop or mobile device to us, you are communicating with us electronically. You
  consent to receive communications from us electronically. We may communicate with you in a variety of ways, such as by
  email, text, in-app push notifications, or by posting notices and messages on the Service, Site, Content, or Features.
  You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically
  satisfy any legal requirement that such communications be in writing. You also consent to receiving promotional
  messages, offers, surveys, and requests electronically. For more information as to how we communicate with you, please
  review our Privacy Policy at http://www.starz.com/privacypolicy. If you no longer want to receive non-transactional
  communications, you may unsubscribe at any time using the link at the bottom of the electronic communication.</p>
<p>27. GOVERNING LAW; ARBITRATION. These Terms of Use will be governed by and construed in accordance with the laws of
  the State of California. All controversies, disputes or claims arising out of or relating to these Terms of Use will
  be determined pursuant to the mediation and arbitration procedures of JAMS, and administered by JAMS or its successor
  (<strong>"JAMS"</strong>) in accordance with the comprehensive rules and procedures, including the optional appeal
  procedure, of JAMS (<strong>"JAMS Rules"</strong>), as modified by these Terms of Use. The parties will endeavor first
  to attempt to resolve the controversy or claim through mediation administered by JAMS before commencing any
  arbitration. All mediation and arbitration will be conducted in Los Angeles County, California. The arbitration will
  be held before a single neutral arbitrator. The JAMS Rules for selection of mediators and arbitrators will be
  followed, except that the mediator or arbitrator will be an experienced entertainment or labor or employment
  arbitrator licensed to practice law in California, or a retired judge. Any appellate panel will consist of three (3)
  neutral members, subject to the foregoing requirements. Any mediation and/or arbitration will be confidential (except
  as information may be required in any judicial proceeding brought to enforce these arbitration provisions or any award
  rendered hereunder). Upon conclusion of any arbitration proceedings hereunder, the arbitrator will render findings of
  fact and conclusions of law and a written opinion setting forth the basis and reasons for any decision he or she has
  reached and will deliver such documents to each party to the agreement along with a signed copy of the award. Any
  arbitration award will be final and binding upon the parties. Judgment upon an award rendered by an arbitrator may be
  entered in any court having jurisdiction over the relevant party or its assets. The arbitrator will have the right to
  award money damages and other appropriate relief, consistent with the terms of this agreement; however, the arbitrator
  will not have the right to award injunctive relief against either party or to certify a class action of any kind. Any
  dispute, claim or controversy arising out of or relating to the Service or your use of the Service and/or the Site,
  and these Terms of Use, must be filed within one (1) year of the relevant events. You and Starz agree that each may
  bring claims against the other only in your or its individual capacity, and not as a plaintiff or class member in any
  purported class or representative proceeding. </p>
<p>28. CALIFORNIA CONSUMER NOTICE. Under California Civil Code Section 1789.3, California users are entitled to the
  following consumer rights notice: This Site, Service, Features and Content are provided by Starz Entertainment, LLC,
  8900 Liberty Circle, Englewood, CO 80112. If you have a question or complaint regarding the Site or Service, please
  contact Customer Service at <a href="mailto:help@starz.com">help@starz.com</a>. You may also contact us by writing
  Starz Entertainment, LLC, 8900 Liberty Circle, Englewood, CO 80112. California residents may reach the Complaint
  Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by post at 1625
  North Market Blvd., Sacramento, CA 95834 or by telephone at (916) 445-1254 or (800) 952-5210 or Hearing Impaired at
  TDD (800) 326-2297 or TDD (916) 322-1700.</p>
<p>28.29. MISCELLANEOUS. These Terms of Use set forth the nature and extent of your relationship with Starz as it
  pertains to the Service, Site, Content and Features. The Terms of Use are binding upon and inure to the benefit of
  Starz and you, and Starz’s successors and assigns, if any. If any provision of these Terms of Use is determined to be
  invalid or unenforceable, such determination will not affect the validity or enforceability of any other provision of
  the Terms of Use. Any waiver of any breach or failure to enforce any provision of the Terms of Use will not be deemed
  a future waiver of such term or a waiver of any other provision. Any waiver of any provision of the Terms of Use will
  be effective only if it is in writing and signed by Starz and you. Nothing contained in the Terms of Use will
  constitute, or enable the development of a joint venture, partnership or any other employee/employer or independent
  contractor relationship or any other commercial relationship (except as seller and customer) between you and Starz.
  The provisions of the Terms of Use, which by their nature should survive expiration or termination of the Terms of
  Use, will survive such termination. You agree that your use of the Service, Site, Content and Features enables you to
  enter into agreements and/or to make purchases electronically. You acknowledge and agree that your electronic
  submissions constitute your agreement and intent to be bound by any such agreements and/or purchases, including
  without limitation all transactions you enter into in connection with the Service, Site, Content and Features.</p>
<p> </p>
<p>REVISED 12/12/19.<br><br><br></p>
			<div class="article__actions">
					<a (click)="closeSection()" class="btn">Go Back</a>
				</div><!-- /.article__actions -->
			
			</div>
		</div>
	</div><!-- /.shell -->
</div><!-- /.article -->

