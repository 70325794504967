<section class="section-alt sweep_submitted score" >
	<div class="shell">
		<div class="section__inner">
			<span *ngIf="!CampSvc.enrolled">
				<h1 class="h2" >{{CS.entry_success_copy}}</h1><!-- /.h2 -->	
				<h2 class="h5">YOUR ENTRY HAS BEEN RECEIVED!</h2><!-- /.h5 -->
			</span>
			<span *ngIf="CampSvc.enrolled">
				<h1 class="h2">{{CS.entry_fail_copy}}</h1><!-- /.h2 -->
				<h2 class="h5">LOOKS LIKE YOU’VE ALREADY SUBMITTED YOUR ENTRY TODAY!</h2><!-- /.h5 -->
			</span>
			<p *ngIf="CS.sweeps_frequency!=='one-off' ">Come back {{CS.entry_frequency_limit}} between {{ CS.sweeps_start | date :'MMMM d'}} and {{ CS.sweeps_end | date :'MMMM d'}} for another chance to enter to win! </p>

			<div class="section__actions">
				<a href="/home/{{CS.campaign_id}}/0" target="_self" class="btn"  analytics-on="click" analytics-event='clicked on retake the quiz sweep'>Retake the Quiz</a>

				<a href="https://www.starzcsr.com/" target="_self" class="btn"  analytics-on="click" analytics-event='clicked on return to starzcsr'>Return to Site</a>
			</div><!-- /.section__actions -->
		</div><!-- /.section__inner -->
	</div><!-- /.shell -->
</section><!-- /.section-alt sweep_submitted-->

