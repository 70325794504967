<section class="landing stage" [ngClass]="{'currentstage' : CampSvc.currentState=='landing'}">
	<div class="hero hero--type1">
		<div class="shell shell--medium">
			<div class="hero__head hero__head--alt hero__head--alt2">
				<h1 class="h3" [innerHTML]="(CS.sweeps_active ? CS.campaign_title_sweeps: CS.campaign_title_default)"></h1><!-- /.h3 -->
				<p [innerHTML]="(CS.sweeps_active ? CS.campaign_paragraph_sweeps: CS.campaign_paragraph_default)"></p>
			</div><!-- /.hero__head -->

			<div class="hero__body" style="background-image: url({{CS.campaign_landing_image}});">
				<a (click)="beginQuiz();" class="btn btn--size1 btn--red">
					<span>{{CS.cta_copy !=='' && CS.cta_copy!== null ? CS.cta_copy : 'Click here to begin!'}}</span>
				</a> <br>

				<a (click)="CS.show_popup=true;" class="link-alt">Click for instructions on how to play</a>
			</div><!-- /.hero__body -->
		</div><!-- /.shell -->
	</div><!-- /.hero -->

	<div class="slider" *ngIf="CS.sweeps_active" >
		<div class="shell">
			<div class="slider__head">
				<h2 class="h4">{{CS.prizing_section_title}}</h2><!-- /.h4 -->
			</div><!-- /.slider__head -->
			
			<div class="slider__clip js-slider swiper-container">
				<app-prizing [Prizing]="CS.prizing"></app-prizing>
			</div>
			
<!--
			<div class="slider__clip js-slider swiper-container" swiper-directive prizing='CS.prizing' ng-init="console.log('live');">
			</div><!-- /.slider__clip -->
		</div><!-- /.shell -->
	</div><!-- /.slider js-slider -->
	


</section><!-- /.section landing-->

