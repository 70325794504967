
<section class="section section-matching">
	<div class="shell">
		<div class="questions" 
				 *ngFor="let question of content.questions; let i=index" id="q{{question.question_id}}"  [ngClass]="{'active': i==current_q, 'inactive':i!==current_q }" >
		<div class="section__inner question" id="Q{{i}}"  
					 [ngClass]="{'active': current_q==i,'inactive': current_q!==i }" >
		
			<header class="section__head" *ngIf="!question.answered" [innerHTML]="question.question_copy | safeHtml">
<!--
				<h3>Who said it?</h3>

				<p>Match the Power Book IV: Force character to their iconic quote.
					Drop and drag the character to the correct quote.</p>
-->
			</header><!-- /.section__head -->
			<p class="mobile_p" *ngIf="!question.answered && isMobile" ><i>Dragging is not available on your device.  Please tap to select and highlight the option of your choice, and then tap the destination to move it there.</i></p>
			<header class="section__head"  *ngIf="question.answered">
						<h3>Correct matches: {{question.points}} out of {{question.max_points}}</h3>
			</header><!-- /.section__head -->

			<div class="section__body" [ngClass]="{'section__body--large':question.answered}">
				<div class="" [ngClass]="{'grid grid--alt' : !question.answered}">
					<div class="grid__col grid__col--size1" *ngIf="!question.answered">
						<div class="boxes">
							<ng-container *ngIf="!isMobile">
							<div class="boxes__item" *ngFor="let choice of question.choices">
								<div class="box box--choice js-box-choice" #boxChoices
									[attr.data-answer-id]="choice.choice_id" id="{{choice.choice_id}}"
									 [ngClass]="{ 'box--small': question.choices[0].choice_img == null,'box--alt': question.choices[0].choice_img !== null && question.matches[0].match_img !== null}" 
									(dragstart)="onDragStart($event)" [class.disabled]="!isDraggable" 
									 >
									 <!--draggable="true" (dragstart)="handleDragStart($event)"--> 
									<div class="box__inner">
										<figure class="box__image">
											<img src="{{choice.choice_img}}" width="168" height="124" alt="{{choice.choice_alt}}">
										</figure><!-- /.box__image -->

										<h6 class="supertitle">{{choice.choice_copy}}</h6>
									</div><!-- /.box__inner -->
									<h6 class="subtitle">{{choice.choice_copy}}</h6>
								</div><!-- /.box -->
							</div><!-- /.boxes__item -->
							</ng-container>
							
							<ng-container *ngIf="isMobile">
							<div class="boxes__item" *ngFor="let choice of question.choices">
								<div class="box box--choice js-box-choice ismobile" #boxChoices
									[attr.data-answer-id]="choice.choice_id" id="{{choice.choice_id}}"
									 [ngClass]="{ 'box--small': question.choices[0].choice_img == null,'box--alt': question.choices[0].choice_img !== null && question.matches[0].match_img !== null}" 
									  (click)="selectChoice($event)"
    								  [class.is-selected]="choice.isSelected"
									 >
									 <!--draggable="true" (dragstart)="handleDragStart($event)"--> 
									<div class="box__inner">
										<figure class="box__image">
											<img src="{{choice.choice_img}}" width="168" height="124" alt="{{choice.choice_alt}}">
										</figure><!-- /.box__image -->

										<h6 class="supertitle">{{choice.choice_copy}}</h6>
									</div><!-- /.box__inner -->
									<h6 class="subtitle">{{choice.choice_copy}}</h6>
								</div><!-- /.box -->
							</div><!-- /.boxes__item -->
							</ng-container>

						</div><!-- /.boxes -->
					</div><!-- /.grid__col -->

					<div class="" [ngClass]="{'grid__col' : !question.answered}">
						<div class="boxes"   [ngClass]="{'boxes--result' : question.answered}">
							<ng-container *ngFor="let match of question.matches">
								<div class="boxes__item boxes__item--1of2" [ngClass]="{'correct':match.correct &&question.answered, 'incorrect':!match.correct &&question.answered}">
									<span class="boxes__item-result" *ngIf="question.answered && !match.correct">Correct answer: {{match.answer}}</span>
									<ng-container *ngIf="!isMobile">
									<div class="box box--answer js-box-answer" #boxAnswers 
										 [attr.data-pick-id]="0" [attr.data-match-id]="match.correct_match" id="space{{match.match_id}}"
										 [ngClass]="{
													'box--small': question.choices[0].choice_img == null,
													'box--alt': question.choices[0].choice_img !== null && question.matches[0].match_img !== null, 
													'correct':match.correct &&question.answered, 'incorrect':!match.correct &&question.answered
													}" 
										 >
										
										<div class="box__inner">

										</div><!-- /.box__inner -->
									</div><!-- /.box -->
									</ng-container>
									<ng-container *ngIf="isMobile">
									<div class="box box--answer js-box-answer ismobile" #boxAnswers 
										 [attr.data-pick-id]="0" [attr.data-match-id]="match.correct_match" id="space{{match.match_id}}"
										 [ngClass]="{
													'box--small': question.choices[0].choice_img == null,
													'box--alt': question.choices[0].choice_img !== null && question.matches[0].match_img !== null, 
													'correct':match.correct &&question.answered, 'incorrect':!match.correct &&question.answered
													}" 
										 (click)="placeClone($event)"
										 [class.is-available]="placing"
										 >
										
										<div class="box__inner">
											

										</div><!-- /.box__inner -->
									</div><!-- /.box -->
									</ng-container>
								</div><!-- /.boxes__item boxes__item--1of2 -->

								<div class="boxes__item boxes__item--1of2 box__match">
									<div class="box box--question " 
										 [ngClass]="{'box--small': question.choices[0].choice_img == null,'box--alt': question.choices[0].choice_img !== null && question.matches[0].match_img !== null, 'correct':match.correct &&question.answered, 'incorrect':!match.correct &&question.answered}" >
										<div class="box__inner">
											<p>{{match.match_copy}}</p>
											
											<figure class="box__image box__image--no-gradient">
												<img src="{{match.match_img}}" width="168" height="124" alt="">
											</figure><!-- /.box__image -->
										</div><!-- /.box__inner -->
										
										<h6>{{match.match_copy}}</h6>
										
									</div><!-- /.box -->
								</div><!-- /.boxes__item boxes__item--1of2 -->
								
								
								
							</ng-container>
						</div><!-- /.boxes -->
						<div class="section__entry" *ngIf="question.answered && question.answer_detail" [innerHTML]="question.answer_detail">
														
						</div><!-- /.section__entry -->
					</div><!-- /.grid__col -->
				</div><!-- /.grid -->
			</div><!-- /.section__body -->

			<div class="section__actions">
				<a (click)="submit_answer(question)" class="btn" [ngClass]="{'btn--disabled': !checkAvail}">
					
					
					{{!question.answered? 'Submit Matches': (current_q+1 >=content.questions.length)? 'Check Your Score': 'Next Question' }}</a><!-- /.btn -->
			</div><!-- /.section__actions -->
<!--
			<div class="section__foot">
				<ul class="list-dev">
							<li *ngFor="let x of [].constructor(content.questions?.length) let k=index" [ngClass]="{'current':k<=i }" style="width:calc(100% / {{content.questions?.length}})"></li>
						</ul><!-- /.list-dev --
			</div><!-- /.section__foot -->
		</div><!-- /.section__inner -->
		</div><!--.questions-->
	</div><!-- /.shell -->
</section><!-- /.section -->