import { Component, Input, OnInit } from '@angular/core';
//Services

@Component({
  selector: 'app-prizing',
  templateUrl: './prizing.component.html',
  styleUrls: ['./prizing.component.scss']
})
export class PrizingComponent implements OnInit {
   @Input() Prizing: any | [];
   public campaign_landing: any;
   constructor() { }

  ngOnInit(): void {
	  //invoke swiper
	  console.log('prizes',this.Prizing);
  }
  togglePrize(prize){
	  
	  prize.show_info=!prize.show_info;
	  console.log('clicked'+this.Prizing);
	  
  }

}