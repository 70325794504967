<section id="score_sweep" class="score score_sweep" >
	<div class="section-form">
		<div class="shell shell--large">
			<div class="section__inner">
				<div class="section__widget">
					<div class="widget js-chart" *ngIf="CS.format_id<4 || CS.format_id==7">
						
						<svg viewBox="0 0 36 36" class="circular-chart">
							<path class="circle"
								attr.stroke-dasharray="{{CampSvc.percentScore }}, 100"
								d="M18 2.0845
								  a 15.9155 15.9155 0 0 1 0 31.831
								  a 15.9155 15.9155 0 0 1 0 -31.831"
							/>
						</svg>

						<div class="widget__content">
							<h1 class="h5">Congratulations</h1><!-- /.h5 -->

							<p>You scored</p>

							<h2 class="h1">
								<span id="value" >{{CampSvc.growing_score}}</span>%
							</h2><!-- /.h1 -->
						</div><!-- /.widget__content -->
					</div><!-- /.widget -->
					
					<div class="feature  feature--alt"  *ngIf="(CS.format_id==4 ||CS.format_id==6)  && CampSvc.personality!=null">
						<figure class="feature__image" style="background-image: url({{CampSvc.personality.rank_image}} );"></figure><!-- /.feature__image -->

						<span [innerHTML]="CS.result_header"></span>

						<h1 class="feature__title">{{CampSvc.personality.rank_title}}</h1><!-- /.feature__title -->

						<p>{{CampSvc.personality.rank_description}}</p>
					</div><!-- /.feature -->


					<ul class="list-products section__list">
						<li>
							<div class="product product--alt">
								<div class="product__info" [ngClass]="{'more__loaded': show_info}">
									<a  class="product__close js-close" (click)="togglePrize();">close [ x ]</a>

									<div class="product__info-entry">
										<p [innerHTML]="CS.current_prizing.prize_description"><!--<strong>PRIZING:</strong>(June 28 - July 11)<br><br>
											<strong>Grand Prize:</strong><br>Sony PlayStation 5<br><br>
											<b>1<sup>st</sup>Runner Up Prize:</b><br>Theragun Elite<br><br>
											<b>2<sup>nd</sup>Runner Up Prize:</b><br>Philips AirFryer XXL<br><br>
											<b>3<sup>rd</sup>Runner Up Prize:</b><br>Bumpboxx Flare6 Boombox<br>-->
										</p>
									</div><!-- /.product__info-entry -->
								</div><!-- /.product__info -->

								<h2 class="h6">Current Prizing</h2><!-- /.h6 -->

								<figure>
									<img class="product__image" 
									[src]="CS.current_prizing.prize_image != null ? CS.current_prizing.prize_image : CS.campaign_prizing_overall" alt="{{CS.current_prizing.prizing_name}}">
									<!--src="{{CS.campaign_prizing_overall}}" -->
								</figure>

								<p>{{CS.current_prizing.prizing_name}}</p>

								<a class="link js-more" (click)="togglePrize();" analytics-on="click" analytics-event='showinfo for Current Round Prizing'>Learn More</a>
							</div><!-- /.product -->
						</li>

					</ul><!-- /.list-products section__list -->
				</div><!-- /.section__widget -->

				<div class="section__form">
					<div class="form" [ngClass]="{'form--error': submitted && entryForm.invalid || submitted && ES.submission_error }">
						<!--<form action="?" method="post">-->
						<form #entryForm="ngForm" name="entry_Form" novalidate autocomplete="off">
						<!--<form name="entryForm" class="validate" novalidate ng-submit="submitted = true;submitEntry(entryForm, form)">-->
							<div class="form__head">
								<h2 class="h3">Complete your sweeps entry.</h2><!-- /.h3 -->

								<p>Submit your information below and you could be one of the lucky winners. </p>

								<div class="slider slider--alt form__slider">
									<div class="slider__clip js-slider-mobile swiper-container" prizing='CS.prizing' swiper-form>
										</div><!-- /.slider__clip -->
								</div><!-- /.slider js-slider -->
							</div><!-- /.form__head -->

							<div class="form__body">
								<div class="form__row">
									<div class="form__col">
										
										<div class="form__field" [ngClass]="{'form__field--error' : fullname.dirty && fullname.errors?.required || fullname.untouched && submitted }">
											<label for="fullname" class="form__label">Full Name</label>

											<div class="form__controls">
												<input type="text" 
													   class="field field--placeholder" 
													   name="full_name" 
													   id="full_name" 
													    #fullname="ngModel"
													   [(ngModel)]="form.fullname"
													   required>
											</div><!-- /.form__controls -->
										
										</div><!-- /.form__field -->

										<div class="form__field" [ngClass]="{'form__field--error' : phn.dirty && phn.errors?.required || phn.untouched && submitted }">
											<label for="phone" class="form__label">Phone Number</label>

											<div class="form__controls">
												<input type="tel" class="field field--placeholder" 
													   name="phone" 
													   id="phone" 
													    #phn="ngModel"
													   [(ngModel)]="form.phone"
													   minlength="10"
													   required>
											</div><!-- /.form__controls -->
										</div><!-- /.form__field -->

										<div class="form__field" [ngClass]="{'form__field--error' : eml.dirty && eml.errors?.required || eml.dirty && eml.invalid || eml.untouched && submitted }">
											<label for="email" class="form__label">Email Address</label>

											<div class="form__controls">
												<input type="text" email class="field field--placeholder" 
													   name="email" 
													   id="email" 
													   #eml="ngModel"
													   [(ngModel)]="form.email"

													   required>
											</div><!-- /.form__controls -->
										</div><!-- /.form__field -->

										<div class="form__field" [ngClass]="{'form__field--error' : supveml.dirty && supveml.errors?.required || supveml.dirty && supveml.invalid || supveml.untouched && submitted }">
											<label for="supervisoremail" class="form__label">Supervisor Email Address</label>

											<div class="form__controls">
												<input type="text" email class="field field--placeholder" 
													   name="supervisoremail" 
													   id="supervisoremail" 
													    #supveml="ngModel"
													   [(ngModel)]="form.supervisoremail"

													   required>
											</div><!-- /.form__controls -->
										</div><!-- /.form__field -->
									</div><!-- /.form__col -->

									<div class="form__col">
										<div class="form__field" [ngClass]="{'form__field--error' : empl.dirty && empl.errors?.required || empl.untouched && submitted }">
											<label for="employeeid" class="form__label">Employee ID</label>

											<div class="form__controls">
												<input type="text" class="field field--placeholder" 
													   name="employeeid" 
													   id="employeeid" 
													    #empl="ngModel"
													   [(ngModel)]="form.employeeid"

													   required>
											</div><!-- /.form__controls -->
										</div><!-- /.form__field -->

										<div  class="form__field" [ngClass]="{'form__field--error' : mvpd.dirty && mvpd.errors?.required || mvpd.untouched && submitted }">
											<label for="employer" class="form__label">Employer</label>

											<div class="form__controls">
												<div class="select">
													<select class="field field--select" [ngClass]="{'error': mvpd.dirty && mvpd.errors?.required || mvpd.errors?.required && submitted}" name="employer" id="employer"  #mvpd="ngModel"
													   [(ngModel)]="form.employer" required>
													<!--MVPD's to be added to campaign-->

														<option value="Altice">Altice</option>
														<option value="Comcast">Comcast</option>
														<option value="Cox">Cox</option>
														<option value="Dish">Dish</option>
														<option value="Mediacom">Mediacom</option>
														<option value="Verizon">Verizon</option>
														<option value="Other">Other</option>

													</select>
												</div><!-- /.select -->
											</div><!-- /.form__controls -->
										</div><!-- /.form__field -->

										<div class="form__field" [ngClass]="{'form__field--error' : supvname.dirty && supvname.errors?.required || supvname.untouched && submitted }">
											<label for="supervisorname" class="form__label">Supervisor Name</label>

											<div class="form__controls">
												<input type="text" class="field" name="supervisorname" id="supervisorname" #supvname="ngModel"
													   [(ngModel)]="form.supervisorname" placeholder="Supervisor Name" required>
											</div><!-- /.form__controls -->
										</div><!-- /.form__field -->

										<div class="form__checkboxes">
											<div class="checkbox" [ngClass]="{'error' : terms.dirty && terms.errors?.required || terms.untouched && submitted }">
												<input type="checkbox" id="agree" name="agree" #terms="ngModel" [(ngModel)]="form.agree"  required>
												<label for="agree">I agree to the <a  	
													(click)="openSection('terms')"
													analytics-on="click"
													analytics-event="Clicked on Terms Link">Terms of Service.</a></label>	


											</div><!-- /.checkbox -->

											<div class="checkbox">
												<!--
												<input type="checkbox" id="subscribe" name="subscribe" [(ngModel)]="form.subscribe" #sub="ngModel">

												<label for="subscribe">I would like to receive STARZ CSR emails in the future.</label>	-->	
												<input type="hidden" id="campaign_id" [(ngModel)]="form.campaign_id" name="campaign_id" #camp_id="ngModel">
											</div><!-- /.checkbox -->
										</div><!-- /.form__checkboxes -->
									</div><!-- /.form__col -->
								</div><!-- /.form__row -->
							</div><!-- /.form__body -->

							<div class="form__actions">
								 <button (click)="submit_enrollment(entryForm.form);"  class="form__btn btn btn--red">
									<span>Submit</span>
								 </button>
								<p class="error__response" *ngIf="entryForm.invalid && submitted">Please complete all required fields.<br>
								<span class="error__response" *ngIf="entryForm.invalid && submitted && eml.invalid && eml.dirty">Note: the email you've entered is invalid.<br></span>
								<span class="error__response" *ngIf="entryForm.invalid && submitted && supveml.invalid && supveml.dirty">Note: the supervisor email you've entered is invalid.<br></span>
								<span class="error__response" *ngIf="entryForm.invalid && submitted && phn.invalid && phn.dirty">Note: the phone number you've entered is invalid.</span></p>

							</div><!-- /.form__actions -->
						</form>
					</div><!-- /.form -->
				</div><!-- /.section__form -->
			</div><!-- /.section__inner -->
		</div><!-- /.shell -->
	</div><!-- /.section-form -->


</section><!-- /.section score_sweep (entry)-->

