
	<section id="score" class="score section-alt section-alt--dev" *ngIf="!CS.sweeps_active">
		<div class="shell">
				<div class="section__inner">
					<div class="widget js-chart" *ngIf="CS.format_id<4 || CS.format_id==7">
						<svg viewBox="0 0 36 36" class="circular-chart">
							<path class="circle"
								attr.stroke-dasharray="{CampSvc.percentScore }}, 100"
								d="M18 2.0845
								  a 15.9155 15.9155 0 0 1 0 31.831
								  a 15.9155 15.9155 0 0 1 0 -31.831"
							/>
						</svg>
						
						<div class="widget__content">
							<h1 class="h5">Congratulations</h1><!-- /.h5 -->

							<p>You scored</p>
							
							<h2 class="h1">
								<span id="value" >{{CampSvc.growing_score}}</span>%
							</h2><!-- /.h1 {{CS.percent_score  | number:0}}-->
						</div><!-- /.widget__content -->
					</div><!-- /.widget -->

					<div class="feature"  *ngIf="(CS.format_id==4 ||CS.format_id==6)  && CampSvc.personality!=null">
						<figure class="feature__image" style="background-image: url({{CampSvc.personality.rank_image}} );"></figure><!-- /.feature__image -->

						<span [innerHTML]="CS.result_header"></span>

						<h1 class="feature__title">{{CampSvc.personality.rank_title}}</h1><!-- /.feature__title -->

						<p>{{CampSvc.personality.rank_description}}</p>
					</div><!-- /.feature -->
					
					<div class="section__actions">
						<a href="/{{CS.campaign_id}}" target="_self" class="btn"  analytics-on="click" analytics-event='retake the quiz'>Retake the Quiz</a>

						<a href="https://www.starznow.com/" target="_self" class="btn"  analytics-on="click" analytics-event='clicked on return to starznow' *ngIf="CS.sweep_status!='eligible'">Return to Site</a>
						
						<a href="https://www.starzcsr.com/" target="_self" class="btn"  analytics-on="click" analytics-event='clicked on return to starzcsr' *ngIf="CS.sweep_status=='eligible'">Return to Site</a>
						
					</div><!-- /.section__actions -->
				</div><!-- /.section__inner -->
			</div><!-- /.shell -->
	</section><!-- /.section-alt score non-sweeps-->

