import { Component, OnInit,Input } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-bracket',
  templateUrl: './bracket.component.html',
  styleUrls: ['./bracket.component.scss']
})
export class BracketComponent implements OnInit {
	@Input() CS: any | [];
	@Input() content:  any | [];
	 @Input() currentState: string;
	submittable=false;
	round_card_visible=true;
	bracket:  any | [];
	current_match=1;
	current_round=1;
	current_q=0;
	percent_score=0;
	current_choice_index='none';
	

  constructor(public CampSvc: CampaignService) { }
  ngOnInit(): void {
	  this.bracket=this.content.bracket[0];
  }
	clearDescendants(dest){
		console.log('got here with', dest);
		let x:number=0;
		this.bracket.matches.forEach(function(obj1){
			obj1.options.forEach(function(obj2){
				if(obj2.position==dest){
					obj2.choice=[];
					x=obj2.destination;
				}
			});
		});
		return x;
	}
	nextRound(){
		this.round_card_visible=false;
	}
	goBack(){
		this.current_q=this.current_q-1;
		this.nextRound();
	}
	setPosition(pos, choice){
		var that=this;
		this.bracket.matches.forEach(function(obj1){
			obj1.options.forEach(function(obj2){
				if(obj2.position==pos){
					obj2.choice=choice;
					console.log('dest', obj2.destination);
					let i: number = obj2.destination;
					while (i >0) {
						i=that.clearDescendants(i);
					}
				}
			});
		});
		//search for destinations and loop through while thye remain
	}
	pickMatch(match,option){
		//set option selected
		match.options.forEach(function(obj1){
			obj1.selected=0;
		});
		option.selected=1;
		if(option.destination>0){
			this.setPosition(option.destination, option.choice);
			this.nextSlot();
			this.current_round=match.round;
			console.log('q',this.current_q);
			console.log('bracket', this.bracket.matches);
		}else{
			//pick winner
			this.bracket.winner_id=option.choice.choice_id;
			option.choice.rank_image=option.choice.choice_img;
			option.choice.rank_title=option.choice.choice_copy;
			option.choice.rank_description=option.choice.result_desc;
			this.CampSvc.personality=option.choice;
			this.CampSvc.currentState="score";
		}
		
	}
	getRound(new_q){
		
		
	}
	
	prevSlot(){
		var matchup=this.bracket.matches[this.current_q];
		if(matchup.round_order>1){
			this.current_q=this.current_q-1;
		} else if(matchup.round>1){
			this.current_q=this.current_q-1;
			//flag round card?
		}
	}
	nextSlot(){
		var matchup=this.bracket.matches[this.current_q];
		if(matchup.round_order < matchup.round_total){
			this.current_q=this.current_q+1;
		}else if(matchup.round_order == matchup.round_total){
			var next=true;
			for (let obj of this.bracket.matches) {
			  let answered=0;
				if(obj.round==matchup.round){
					obj.options.forEach((child) => {
						answered += child.selected;
					});
					if(answered !==1){
						this.current_q=obj.match-1;
						next=false;
						break;
					}
					
				}
				
			}
			if(next){
				this.current_q=this.current_q+1;
				//this.current_round=this.current_round+1;
				console.log('view card');
				this.round_card_visible=true;
				
			}
		}

	}

}

