import { Component, OnInit, Input } from '@angular/core';

import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.html',
  styleUrls: ['./articles.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(public CampSvc:CampaignService) { }

  ngOnInit(): void {
  }

  closeSection(){
	  this.CampSvc.currentState=this.CampSvc.previousState;
	  this.scrollToElement(this.CampSvc.currentState);
  }
	scrollToElement(anchor): void {
		var element=document.getElementById(anchor);
		setTimeout(()=> { element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});}, 200);
	}
}
