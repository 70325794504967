
<div class="wrapper__background"  ></div><!-- /.wrapper__background-->
<div class="main__body">
	<section class="section">
		<div class="section__head">
			<div class="title">
				<h1>Campaign Overview</h1>
			</div><!-- /.title -->
		</div><!-- /.section__head -->
		<!--<app-filter-promo_yr></app-filter-promo_yr>-->
	</section>
  <!--Add new Account form section -->

  <section class="section" >
    <div class="section__body">
	
      <div class="manage-overview-cta">
    		<a class="btn btn--red" routerLink="/campaign-detail">Add New Account</a>
      </div>
      <div class="tabs">
		 
		  <div class="tabs__body">
			<div class="tab section__body" >
				<div class="box">
					<header class="box__head" style="background-image: url(assets/images/temp/background-img-violet.png);">

					  <h3>CAMPAIGNS</h3>
					</header><!-- /.box__head -->
					<div class="box__body">
						  <div class="table table--manage-account-list">
							<table>
							  <thead>
								<tr>

								  <th>VIEW</th>

									<th><a (click)="sortBy('campaign_id')">STATUS</a></th>

								  <th><a (click)="sortBy('campaign_id')">Campaign ID</a></th>

								  <th><a (click)="sortBy('format_name')">Quiz Type</a></th>
									
								  <th><a (click)="sortBy('campaign_name')">Campaign Name</a></th>

								  <th><a (click)="sortBy('sweeps_start')">Sweep Start</a></th>

								  <th><a (click)="sortBy('sweeps_end')">Sweep End</a></th>
									
								  <th>CTA</th>

								</tr>
							  </thead>

							  <tbody>
								<tr *ngFor=" let campaign of Campaigns | sortBy: reverse : propertyName">
								  <td>
									<a routerLink="/campaign-detail/{{campaign.campaign_id}}"  class="btn btn--red">View Details</a>
								  </td>

								  <td>
									<h6>Status</h6>
								  </td>


								  <td>
									<h6>{{campaign.campaign_id}}</h6>
								  </td>
									
								  <td>
									<h6>{{campaign.quiz_type}}</h6>
								  </td>

								  <td>
									<h6>{{campaign.campaign_title_default}}</h6>
								  </td>

								  

								  <td>
									<h6>{{campaign.sweeps_start}}</h6>
								  </td>

								  <td>
									<h6>{{campaign.sweeps_end}}</h6>
								  </td>

								  <td>
									  <a class="btn btn--red">Preview Sweep</a>
									  <a class="btn btn--red" href="/home/{{campaign.campaign_id}}" target="_blank">Preview Generic</a>

								  </td>


								</tr>
							  </tbody>
							</table>
						  </div><!-- /.table -->
					</div><!-- /.box__body -->
				</div>
			</div><!-- /.tab -->

		  </div><!-- /.tabs__body -->
		</div>
    </div><!-- /.tabs -->
</section><!-- /.section-faq -->

</div>
