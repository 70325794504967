// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
 // apiUrl: 'http://starzcsr-1643-phase2-staging.us-east-1.elasticbeanstalk.com/'
  //apiUrl: 'https://api-staging.starzcsrquiz.com/'
  apiUrl: 'https://api.starzcsrquiz.com/'
 //apiUrl: 'http://heelshalloffame-staging.us-east-1.elasticbeanstalk.com/api/'
	
};

/*8o7c7anrwl
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 [
    {
        "Redirect": {
            "HostName": "starzcsrquiz.com",
            "Protocol": "https",
            "ReplaceKeyPrefixWith": "#/"
        }
    }
]
 */



// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
