import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';


//Services
import { ErrorhandlerService } from './errorhandler.service';

//Environment
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CampaignService{
   public campaignObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
   	show_popup=false;
   	swpFlag=false;
	enrolled=false;
    currentState='landing';//
	previousState="landing";
	videoStarted=false;
	revealBtn=false;
	sweeps_frequency="daily";
    sweeps_period="June 1 and July 11";
	video: any;
	personality: any |[];
	score: any;
	percentScore=0;
	growing_score=0;
	ranking: any |[];

   constructor(
	private http: HttpClient,
	private cookieService: CookieService,
    private errorhandlerService: ErrorhandlerService) {
	
	}
	
	private apiUrl = environment.apiUrl;

	playVideo(content_id){
	  this.videoStarted=true;
	  this.video = document.getElementsByClassName('video__content')[0];
	  this.video.play();
	  this.video.addEventListener('ended',()=>{
      this.launchQuestions(content_id);},false);
	  	setTimeout(()=> { this.revealBtn=true; console.log('time is up');}, 20000);
	  }
	
	launchQuestions(content_id){
		this.video.pause();
		this.currentState='quiz';	
	}
	showScore(origin){
	  if(origin=='quiz'){
		  this.currentState="score";
		  this.growing_score=0;
		  this.countup();
	  }
	}
	countup(){
	  console.log('&score is' +this.percentScore);
	  if(this.percentScore>0){
		  setTimeout(()=> { if(this.growing_score<this.percentScore){
				  this.growing_score++;
				  this.countup();
			  }}, 40);
		 /* var counting= $timeout(function() {
			  
			  if(this.growing_score<this.percentScore){
				  this.growing_score++;
				  this.countup();
			  }
		  }, 40);*/
	  }
	  console.log('then score is' +this.growing_score);
	  //factory.growing_score=factory.percent_score;
  }
		

    //Check the campaign start and end date
	generateCampaign(id){
		this.checkCampaign(id).subscribe((response) => {	
			 //set up data for processing
			 var testInfo=JSON.parse(response.body);
			 //processing issues	
			 var prizing=testInfo.prizing; 
			 var quiz=testInfo.quiz;
				//console.log('returned',testInfo);
		     //var questions=testInfo.questions;
		     var max_score=0;
			 //set up scoring properties
			 quiz.forEach(function(obj1){
				 obj1.questions.forEach(function (obj) {
					obj.answered=0;
					obj.result='correct';
					max_score=max_score+parseInt(obj.max_points);
					 
					obj.choices.forEach(function (choice) {
						choice.selected=false;
					});
				 });
			 });
			 testInfo.max_score=max_score;
			//console.log(prizing);
			if(prizing){
				prizing.forEach( function (obj) {
					obj.show_info=false;
				 });
			}
			//bracket set up
			if(testInfo.format_id==6){
				var that=this;
				quiz.forEach(function(obj1){
					obj1.bracket=that.buildBracket(obj1.questions);
					//console.log('startwith',obj1.questions);
				})	
			}
				
			 //set sweeps
			  if(this.swpFlag){
				  this.cookieService.set( 'sweep_status', 'eligible');
  				  localStorage.setItem( 'sweep_status', 'eligible');
				  //var cookieValue = this.cookieService.get('sweep_status');
				 // $cookies.put('sweep_status', 'eligible');
				  testInfo.sweeps_active=testInfo.sweeps_active; 
				  //console.log( 'Sweeps is' + cookieValue + testInfo.sweeps_active);
				  // Phase 2- set for real factory.sweeps_frequency
				  // Phase 2- set for real factory.sweeps_period
			  }else{
				  testInfo.sweeps_active=false; 
			  }
			  //populate behavior subject
			  this.campaignObj.next(testInfo);
			  
        	});
		
	}
    checkCampaign(id): Observable<any> {
     return this.http.get<any[]>(this.apiUrl + 'campaign/'+id, { observe: 'response' } ); // end of pipe
    }
	buildBracket(q){
		var choices = q[0].choices.sort(() => Math.random() - 0.5);
		var question_copy=q[0].question_copy;
		var rounds=0;
		var slots=0;
		var bracket=[];
		var round_1 =choices.length;
		var positions=[];
		var l=0;
		var pairing=1;
		var toggle=1;
		var current_round=1
		var max_round=round_1;
		var current_count=max_round;
		var choice=[];
		var originval=0;
		var destinationval=0;
		var matches=[];
		var round_order=0;
		if(round_1==16){ rounds=4; slots=30;}
		else if(round_1==8){rounds=3;slots=14;}
		else if(round_1==4){rounds=2;slots=6;}
		else if(round_1==2){rounds=1;slots=2;}
		else {rounds=0; slots=1;}
		for (let matchord=1; matchord<round_1; matchord++){
			var temparr=[];
			//get current round
			if(matchord>(current_count/2)){
			   max_round=max_round/2;
			   current_count=current_count+max_round;
			   current_round++;
			   round_order=0;
			}
			round_order=round_order+1;
			destinationval=(l>(slots-3) ? 0 :(matchord+round_1));
			for(let i=0;i<=1;i++){
				// get origin
				var test=(l+1);
				originval=(l<round_1 ? 0 :(l-round_1+1));
				choice=(l<round_1 ? choices[l]:[]);
				
				l=l+1;
				temparr.push({
					position:l,//1,//2
					selected: 0,
					origin: originval,
					destination:  destinationval,//9.9
					choice: choice
				});
			}
			
			
			matches.push({
				match:matchord,
				round_order: round_order,
				round_total: (max_round/2),
				round: current_round,
				options: temparr,
			});
		}
		/*
		for (let j=0; j<slots; j++){
			l=l+1;
			console.log(toggle);
			pairing=(toggle>2 ? pairing+1 : pairing);
			toggle=(toggle>2? 1: toggle);
			
			var choice=(j<round_1 ? q[j]:[]);
			var originval=(j<round_1 ? 0 :(j-round_1+1));
			var destinationval=(j>(slots-3) ? 0 :(pairing+round_1));
			//calculate round
			if(j>=current_count){
			   max_round=max_round/2;
			   current_count=current_count+max_round;
			   current_round++;
			}
			positions.push({ 
				position: l,
				option: toggle,
				selected: 0,
				round: current_round,
				match: pairing ,
				origin: originval,
				destination:  destinationval,
				choice: choice
			});	
			toggle=toggle+1;	
		}
		*/
		var roundsarr=[];
		var k=0;
		var mtchs=round_1;
		for (let i = 0; i < rounds; i++) {
			mtchs=mtchs/2;
			k=(k+1);
			roundsarr.push({round: k, matches:mtchs});
		}
		bracket.push({question_copy: question_copy, picks: round_1, slots: slots, winner_id: 0, matches: matches, rounds: roundsarr});
		
		//create rounds, add slots with from/to
		//randomize questions
		console.log("bracket is"+ bracket);
		return bracket;
	}
}

