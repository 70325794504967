import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partial-header',
  templateUrl: './partial-header.component.html',
  styleUrls: ['./partial-header.component.scss']
})
export class PartialHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
