import { Component, OnInit, Output, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

//Services
import { CampaignService } from '../services/campaign.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {
   campaign$: any | [];
   questions$: any | [];
   currentState$: any | null;
   public campaign_error:Boolean = false;
   quiz_id='0';


  ngOnInit() {
	    this.route.paramMap.subscribe(params => {	
		   console.log('id',params['id']);
		   console.log('swp',params['swp']);
		   if(parseInt(params.get('id'))>0){
			   this.quiz_id=params.get('id');
			   localStorage.setItem('quizId', this.quiz_id);
		  // }else if (parseInt(localStorage.getItem('quizId'))>0){
			   //this.quiz_id=localStorage.getItem('quizId');
		   }
		   if(parseInt(params.get('swp'))>0){
			   this.CampSvc.swpFlag=true;
		   }
		   console.log('params', params);
		   console.log('id', this.quiz_id);
		   console.log('sweeps', this.CampSvc.swpFlag);
	  });
	   this.CampSvc.generateCampaign(this.quiz_id);
	   this.currentState$ = this.CampSvc.currentState;
	   
//factor in routing
//factori in sweeps variable/campaign variable
	    this.CampSvc.campaignObj.subscribe((data) => { 
            if (data)
				var temp=data;//Object.values(data)[33];
                this.campaign$=data;
			console.log('the campaign',this.campaign$);
        });

  }

  constructor(
	private route: ActivatedRoute,
    public CampSvc: CampaignService
  ) { }

}
