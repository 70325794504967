<section class="section-def section-def--base section-hth">
	<div class="shell">
		<div class="section__inner">
			<div class="section__head">
				<h1 class="h5">QUESTION 1</h1><!-- /.h5 -->
			</div><!-- /.section__head -->

			<div class="section__body">
				<h2 class="h3 section__title">Who is King Henry VIII’s mother?</h2><!-- /.h3 section__title -->

				<div class="section__content">
					<div class="grid">
						<div class="grid__col grid__col--1of2">
							<div class="tile-alt tile-alt--dev">
								<figure class="tile__image" style="background-image: url(assets/images/temp/question_image_1a.png);">
									<a href="#" class="btn btn--alt tile__btn">Submit</a>
								</figure><!-- /.tile__image -->

								<h2 class="h4 tile__title">Cathering of Aragon</h2><!-- /.h4 tile__title -->

								<h2 class="h5 tile__subtitle">from <em>The Spanish Princess</em></h2><!-- /.h5 tile__subtitle -->
							</div><!-- /.tile-alt -->
						</div><!-- /.grid__col grid__col-/-1of2 -->

						<div class="grid__col grid__col--1of2">
							<div class="tile-alt tile-alt--dev">
								<figure class="tile__image" style="background-image: url(assets/images/temp/question_image_1c.png);">
									<a href="#" class="btn btn--alt tile__btn">Submit</a>
								</figure><!-- /.tile__image -->

								<h2 class="h4 tile__title">Elizabeth of York</h2><!-- /.h4 tile__title -->

								<h2 class="h5 tile__subtitle">from <em>The White Princess</em></h2><!-- /.h5 tile__subtitle -->
							</div><!-- /.tile-alt -->
						</div><!-- /.grid__col grid__col-/-1of2 -->
					</div><!-- /.grid -->
				</div><!-- /.section__content -->
			</div><!-- /.section__body -->

			<div class="section__foot">
				<p>1 <span>/ 8</span></p>

				<ul class="list-dev">
					<li class="current"></li>

					<li></li>

					<li></li>

					<li></li>

					<li></li>
				</ul><!-- /.list-dev -->
			</div><!-- /.section__foot -->
		</div><!-- /.section__inner -->
	</div><!-- /.shell -->
</section><!-- /.section -->
