import { Component, OnInit } from '@angular/core';
//Services
import { ContentManagementService } from '../../services/content-management.service';

@Component({
  selector: 'app-campaign-admin',
  templateUrl: './campaign-admin.component.html',
  styleUrls: ['./campaign-admin.component.scss']
})
export class CampaignAdminComponent implements OnInit {
	Campaigns: any | [];
	propertyName="account_id";
	reverse='asc';
	

  constructor(
	public CMS:ContentManagementService,
	) { }

  ngOnInit(): void {
	  this.CMS.getCampaigns().subscribe(response => {
		  //console.log('body', response.body);
		  //var testInfo=JSON.parse(response.body);
		   this.Campaigns=response;
	  });
  }
	 sortBy(field){
	  if(this.propertyName!==field){
		  this.propertyName=field;
		  this.reverse="asc";
	  }else if(this.reverse="asc"){
		  this.reverse="desc";
	  }else{
		  this.reverse="asc";
	  }
  }

}
