import { Component } from '@angular/core';
import { Credentials, S3 } from 'aws-sdk';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContentManagementService } from './content-management.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})

export class S3UploadService {

  // Replace the values with your own
  private readonly _awsConfig = {
    accessKeyId: "AKIAJSU6NLEMHGNHJN7Q",
    secretAccessKey: "ZSobQhkp4WoGjG4wrZ1FWEy51wo6G5RDlZw0dSRM",
    s3BucketRegion: "us-east-1", // example: "us-west-2"
    s3BucketName: "assets.hallmarkaffiliates"    // example: "mycompany.testbucket"
  }
  private _awsCredentials: Credentials;
  private _s3ClientConfig: S3.ClientConfiguration;
  private _s3: S3;
  public adminUploadsObj: BehaviorSubject<any>= new BehaviorSubject<any>([]);
	
  uploading=false;
 // public uploads: any |[];
	uploads=[];
  uploadCTA="Upload A File";
  

  uploadStatus: string =  "(no upload yet)";
  expectEndpoint: any;
  actualEndpoint: any;
/*
	privacy = typeof privacy !== 'undefined' ? privacy : 'public-read';
        var deferred = $q.defer();
        var file_key = this.makeFileKey(file);
        var params = { Bucket: S3_BUCKET, Key: file_key, ContentType: file.type, Body: file,  ACL: privacy };
        */
	
	
	
  constructor(private CS : ContentManagementService, private http: HttpClient) {
    // Create an AWS S3 client
    this._awsCredentials = new Credentials(this._awsConfig.accessKeyId, this._awsConfig.secretAccessKey);
    this._s3ClientConfig = {
      credentials: this._awsCredentials,
      region: this._awsConfig.s3BucketRegion,
      sslEnabled: true
    };
    this._s3 = new S3(this._s3ClientConfig);
	  

    // Set the expected and actual endpoints
    var isRegionUSEast :boolean = (this._awsConfig.s3BucketRegion).toLowerCase() == "us-east-1";
    var endpointHost :string = isRegionUSEast ? "s3" : "s3-" + this._awsConfig.s3BucketRegion
    this.expectEndpoint = endpointHost + ".amazonaws.com";
    this.actualEndpoint = this._s3.config.endpoint;
  }

  // Event triggered when a file has been specified 
	getUploadsAdmin(){
		return this.adminUploadsObj;
	}
	
	secureDownload(asset) {
		//var secure_bucket = new AWS.S3();
		//var asset=asset.replace('https://s3.amazonaws.com/'+S3_BUCKET+'/','');
		//asset=asset.replace('https://s3.amazonaws.com/'+S3_SECURE_BUCKET+'/','');

		this._s3.getSignedUrl('getObject',{ Bucket: 'assets.hallmarkaffiliates', Key: asset }, function(error,data){
		  if (error != null) {
			  alert("Failed to retrieve an object: " + error);
		  } else {
			
			  console.log('data is', data);
			  //alert("Loaded " + data.ContentLength + " bytes");
			window.open(data);
		  }
		});		
	  };
 


	fileEvent(fileInput: any, destination) {
	 //make uploadStatus visible
	var privacy=destination == 'uploads' ? 'private' : 'public-read';
	//if(destination!=='uploads'){this.privacy='read-only';}
	this.uploading=true;
    this.uploadStatus = "starting upload...";

    // get the file to upload
    let file: File = fileInput.target.files[0];
    console.log(file);

    // upload file to S3
	//add account name
    var fname=file.name.replace(' ', '_');
	//fname=fname.replace(/\.[^/.]+$/, "");
	fname=fname.replace(/[\W_]+/g,"_");
    let putObjectRequest: S3.PutObjectRequest = {
      Key: destination+'/' + fname,
      Body: file,
      Bucket: this._awsConfig.s3BucketName,
	  ACL: privacy,
      ContentType: file.type,
      ServerSideEncryption: "AES256"
    };

    // use "that" to be able to reach component properties within the then/catch callback functions
    let that = this;

    // upload to S3
    this._s3.upload(putObjectRequest).promise()
	  //	.then('httpUploadProgress',(progress) => {     
      //           console.log(progress)
       // })
	 // 'httpUploadProgress' — function (progress, response)
      .then(function (response: S3.ManagedUpload.SendData) {
		console.log('started');
		console.log('filename is', file.name);
		console.log(onprogress);
        that.uploadStatus = "Success!";
		var temp=[]
		if(that.uploads?.length>0){
			temp=that.uploads;
		}
		temp.push({filename: file.name, url: response.Location});
		
		that.uploadCTA="Upload Another";
		that.uploads=temp;
		that.adminUploadsObj.next(temp);
		console.log('curupa', that.adminUploadsObj.getValue());
		
		
      })
      .catch(function (err: Error) {
        var errMsg = "";
        errMsg += "upload failed.\n ";
        errMsg += "Error Message: " + err.message + "\n ";
        errMsg += "NOTE: an error message of 'Network Failure' may mean that you have the wrong region or the wrong bucket name.";
        that.uploadStatus = errMsg;
        // alert(errMsg);
      });
	 this._s3.upload(putObjectRequest).on('httpUploadProgress', function (event) {
           console.log('event',event);
        });
  }

}
