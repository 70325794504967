import { Component, OnInit, Input } from '@angular/core';
//Services
import { CampaignService } from '../../services/campaign.service';

@Component({
  selector: 'app-personality',
  templateUrl: './personality.component.html',
  styleUrls: ['./personality.component.scss']
})
export class PersonalityComponent implements OnInit {
	@Input() CS: any | [];
	@Input() content:  any | [];
	@Input() currentState: string;
	currentScore=0;
	submittable=false;
  constructor(public CampSvc: CampaignService) { }

  ngOnInit(): void {
	  console.log('content is', this.content);
  }
	selectChoice(question,choice){
		//record current choice
		question.choices.forEach(function (obj) {
			obj.selected=false;
		});
		choice.selected=true;
		question.answered=1;
		question.answer_id=choice.choice_id;
		question.answer_copy=choice.category;
		if(choice.category!==null){
			this.totalCategories();
			console.log('category');
		}else{
		 //total scores for ranking
			console.log('ranking')
		}
		this.nextQ(question.question_id);
	}
	totalCategories(){
		this.clearCategories();
		var questions=this.content.questions;
		this.content.rankings.forEach(function(item){
			questions.forEach(function (q){
				if(q.answer_copy==item.rank_id){
					item.total++;
				}
			});
		});
		//choose result
	}
	clearCategories(){
		this.content.rankings.forEach(function(item){
			item.total=0;
		});
	}
	getScore(){
		this.currentScore=0;
		this.content.questions.forEach(function(obj){
			if(obj.answered==1){
				obj.choices.forEach(function(choice){
					if(choice.choice_id==obj.answer_id){
						this.currentScore=this.currentScore=+choice.points;
					}
				});
			}
			
		});
		//update ranking
	}
	nextQ(id){
		var q_id=id;
		var begin=false
		this.content.questions.forEach(function(obj){
			if(q_id==id && obj.answered==0 && obj.question_id>id){
			  q_id=obj.question_id;
			}
		});
		if(q_id==id){
			this.content.questions.forEach(function(obj){
				if(q_id==id && obj.answered==0){
				  q_id=obj.question_id;
				}
			});
		}
		if(q_id==id){
			console.log('next stage');
			this.submittable=true;
			this.scrollToElement('submit_btn');
		}else{
			console.log('next q', q_id);
			this.scrollToElement('q'+q_id);
		}
		
	}
	scrollToElement(anchor): void {
		var element=document.getElementById(anchor);
		setTimeout(()=> { element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});}, 200);
	}
	scrollToTop(){
	   window.scroll({ 
			   top: 0, 
			   left: 0, 
			   behavior: 'smooth' 
		});
	}
	submitQuiz(){
		if(this.submittable){
			this.pickResult();
			//set flag for categor vs rank;
		}else{
			console.log('openqs');
		}
		
	}
	pickResult(){
		var score=0;var winner=null;
		this.content.rankings.forEach(function(obj){
			if(obj.total > score) {
				score = obj.total;
				winner = obj;
			}else if(score>0 && obj.total==score){
				console.log('cointoss');
				var x = (Math.floor(Math.random() * 2) == 0);
				if(x){winner=obj;}
			}
		});
		this.CampSvc.personality=winner;
		this.scrollToTop();
		this.CampSvc.currentState="score";
		
	}
	assignRank(){
		var winner=null;
		this.content.rankings.forEach(function(obj){
			if(this.currentScore >=obj.min_score && this.currentScore <obj.max_score) {
				winner = obj;
			}
		});
		this.CampSvc.score=this.currentScore;
		this.CampSvc.ranking=winner;
		this.CampSvc.currentState="score";
	}
									
	 
}
