import { Component, OnInit, Output, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { of } from 'rxjs';
import { map, mapTo, filter, switchMap, tap } from 'rxjs/operators';


//Services
import { CampaignService } from './services/campaign.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit  {
  navigationEnd: any;
  routePathParam: any;
  subRoutePathParam: any;
  title = 'starz1643-app';

	//currentState$: boolean | false;// | 'landing';
   campaign$: any | [];
   questions$: any | [];
   currentState$: any | null;
   public campaign_error:Boolean = false;
   quiz_id='0';
   
	constructor(private route: ActivatedRoute, private router: Router, public CampSvc: CampaignService) { }
 
   ngOnInit() {
	  /* console.log('itis', this.route.snapshot);
	   
	   this.navigationEnd = this.router.events.pipe(
		  filter(event => event instanceof NavigationEnd)
		);

		this.routePathParam = this.navigationEnd
		  .pipe(
			map(() => this.route.root.firstChild),
			switchMap(firstChild => firstChild
			  ? firstChild.paramMap.pipe(map(paramMap => paramMap.get('routePathParam')))
			  : of(null
			  )),
		  )


		this.subRoutePathParam = this.navigationEnd
		  .pipe(
			map(() => this.route.root.firstChild),
			switchMap(firstChild => firstChild && firstChild.firstChild
			  ? firstChild.firstChild.paramMap
				.pipe(map(paramMap => paramMap.get('subRoutePathParam')))
			  : of(null)
			)
		  )
	   
	   console.log('ida: ',this.routePathParam );
	   console.log('swpa: ',this.subRoutePathParam);
	  
	   
	   
	   
	   
	   
	   
	     this.route.paramMap.subscribe(params => {	
		   console.log('id',params['id']);
		    console.log('swp',params['swp']);
		   if(parseInt(params.get('id'))>0){
			   this.quiz_id=params.get('id');
			   localStorage.setItem('quizId', this.quiz_id);
		   }else if (parseInt(localStorage.getItem('quizId'))>0){
			   this.quiz_id=localStorage.getItem('quizId');
		   }
		   if(parseInt(params.get('swp'))>0){
			   this.CampSvc.swpFlag=true;
		   }
		   console.log('params', params);
		   console.log('id', this.quiz_id);
		   console.log('sweeps', this.CampSvc.swpFlag);
	  });
	   this.CampSvc.generateCampaign(this.quiz_id);
	   this.currentState$ = this.CampSvc.currentState;
	   
//factor in routing
//factori in sweeps variable/campaign variable
	    this.CampSvc.campaignObj.subscribe((data) => { 
            if (data)
				var temp=data;//Object.values(data)[33];
                this.campaign$=data;
			console.log('the campaign',this.campaign$);
        }); */
  }
	
	
	
  


}
