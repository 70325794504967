import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-hth',
  templateUrl: './hth.component.html',
  styleUrls: ['./hth.component.scss']
})
export class HthComponent implements OnInit {
	@Input() CS: any | [];
	@Input() content:  any | [];
	 @Input() currentState: string;
  constructor() { }

  ngOnInit(): void {
  }

}
