import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';

//Services
import { CampaignService } from '../../services/campaign.service';
import { S3UploadService } from '../../services/s3upload.service';

@Component({
  selector: 'app-campaign-entry',
  templateUrl: './campaign-entry.component.html',
  styleUrls: ['./campaign-admin.component.scss']
})
export class CampaignEntryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
