<div class="slider__slides swiper-wrapper">
	<div class="slider__slide swiper-slide swiper-slide_{{Prizing?.length}}" *ngFor="let prize of Prizing; let i=index"  swiper-slide ><!-- swiper-slide-->
		<div class="product">
			<div class="product__info" id="prodshow{{i}}" *ngIf="prize.prize_description!==''" [ngClass]="{'more-loaded':prize.show_info}">
				<a  class="product__close js-close" (click)="togglePrize(prize);">close [ x ]</a>
				<!--href="javascript:hideInf({{i}});"-->

				<div class="product__info-entry" [innerHTML]="prize.prize_description"></div>
				<!-- /.product__info-entry -->
			</div><!-- /.product__info -->

			<h2 class="h6">{{prize.prize_category_detail}}</h2><!-- /.h6 -->

			<figure>
				<img class="product__image" src="{{prize.prize_image}}" alt="">
			</figure>

			<p>{{prize.prizing_name}}</p>
			<span *ngIf="prize.prize_description!==''">
				<a class="link js-more" (click)="togglePrize(prize);" >LEARN MORE</a>
			</span>
		</div><!-- /.product -->
	</div><!-- /.slider__slide -->
</div><!-- /.slider__slides -->
<!--
<div class="slider__nav">
<a class="swiper-button-next swiper-slide-prev" href="onclick:mySwiper.onClickNext();"></a>

<div class="swiper-button-prev swiper-slide-next" href="mySwiper.onClickNext();"></div>
</div> /.slider__nav

<div class="slider__pagination swiper-pagination"></div><!-- /.slider__pagination swiper-pagination -->
	
	

											