import { Component, OnInit, Input } from '@angular/core';

import { CampaignService } from '../../services/campaign.service';
//import { SafeHtmlPipe} from '../../safeHtml.pipe';


@Component({
  selector: 'app-base-quiz',
  templateUrl: './base-quiz.component.html',
  styleUrls: ['./base-quiz.component.scss']
})
export class BaseQuizComponent implements OnInit {
	@Input() CS: any | [];
	@Input() content:  any | [];
	 @Input() currentState: string;
	currentScore=0;
	submittable=false;
	current_q=0;
	percent_score=0;
	current_choice_index='none';

  constructor(public CampSvc: CampaignService) { }

  ngOnInit(): void {
	 
	  console.log('contentbase is', this.content.questions);
  }
	submit_answer(choice){
	  var q_arr=this.content.questions[this.current_q];
		console.log(this.current_q+'q-'+choice+'c');
	  var selected_choice=q_arr.choices[choice];
		console.log(selected_choice);
	  q_arr.result='Incorrect';
	 //check answer  
	  if(q_arr.answer_id == selected_choice.choice_id){q_arr.result='Correct'; }
	  //set score 
	  q_arr.points=selected_choice.points;
	  q_arr.answered=1;
	  //update score 	  
	  this.updateScore();
	  this.current_choice_index="none";//clears mobile selection
		
	}
	mobile_select(id){
		this.current_choice_index=id;
	  console.log('selected mobile'+id);
	
		
	}
	
	updateScore(){
	  var temp_score=0;
	  this.content.questions.forEach(function(obj){
		  if(!obj.points){obj.points=0;}
		  temp_score=temp_score+parseInt(obj.points);
	  });
	  this.currentScore=temp_score;
	  this.percent_score=(this.currentScore/this.CS.max_score)*100;
	  //console.log(this.currentScore + ' out of ' + this.CS.max_score+ ' is ' + this.percent_score);
  }
	
	nextQ(){
	  if((this.current_q+1) >=this.content.questions.length){
		this.CampSvc.score=this.currentScore;
		this.CampSvc.percentScore=this.percent_score;
		this.CampSvc.showScore('quiz');	
	  }else{
		  //console.log('currenta'+this.current_q);
	  	this.current_q=this.current_q+1;
		 // console.log('currentb'+this.current_q);
	  } 
  }

}
