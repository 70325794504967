<div class="popup" [ngClass]="{'popup--open' : CS.show_popup}">
	<div class="popup__inner">
		<div class="popup__head">
			<h2 class="h3 popup__title">How to Play the Game</h2><!-- /.h3 -->

			<a  (click)="CS.show_popup=false" class="popup__btn">
				<img src="assets/images/svg/cross.svg" alt="">
			</a>
		</div><!-- /.popup__head -->

		<div class="popup__body">
			<p [innerHTML]="CS.howtoplay"></p>
		</div><!-- /.popup__body -->

		<div class="popup__actions">
			<a (click)="CS.show_popup=false" class="btn">Close</a>
		</div><!-- /.popup__actions -->
	</div><!-- /.popup__inner -->
</div><!-- /.hero__popup popup -->

