<section  class="landing" >
		<div class="hero hero--type1">
			<div class="shell shell--medium">
<figure class="hero__video video" [ngClass]="{'is-playing': CampSvc.videoStarted,'hero__video--alt video': !CS.sweeps_active }">
	<div class="video__inner">

		<video class="video__content" controls=""  preload="auto" >
			<source src="{{content.content_source}}" type="video/mp4">
		</video>

	</div>
	
	<div class="section__button continue" style="padding-top: 30px;" *ngIf="CampSvc.revealBtn">
			<a  (click)="CampSvc.launchQuestions(content.content_id);" class="btn" analytics-on="click" analytics-event='start quiz'>
				Continue
			</a>
		</div><!-- /.section__button -->
</figure><!-- /.video -->
			</div>
	</div>
</section>

