<section class="section-def section-bracket">
	<div class="shell">
		
		<div class="section__inner">
			<div class="questions"  >
<!-- create round object and populate  with resulkts  , 'inactive':i!==current_q -->
<!-- tie in for content/rounds -->

				<div class="section__head" [ngClass]="{'section_round': round_card_visible}">
					<h1 class="h5">Round {{bracket.matches[current_q].round}} <span>/ {{bracket.rounds.length}}</span></h1><!-- /.h5 -->

					<div class="section__head-list">
						<ul class="list-def" *ngFor="let rounds of bracket.rounds;  let i=index" [ngClass]="{'list-current': i==current_round }">
							<li *ngFor="let matchup of bracket.matches | multiFilter: {round: rounds.round}; let j=index" [ngClass]="{'passed': matchup.match<=current_q }" id="{{matchup.match}}"></li>

						</ul><!-- /.list-def -->

					</div><!-- /.section__head-list -->
				</div><!-- /.section__head -->

				<div class="matchups"  *ngIf="!round_card_visible">
					<div class="section__body">
					<h2 class="h3 section__title" [innerHTML]='bracket.question_copy'></h2><!-- /.h3 section__title -->

					<div class="section__content"  *ngFor="let matchup of bracket.matches; let j=index" [ngClass]="j==current_q? 'active':'inactive'">
	<!--Tie in for quiz q for round-->
						<div class="grid grid--dev">
							<div class="grid__col grid__col--1of2" *ngFor="let option of matchup.options">
								<a class="tile-alt" [ngClass]="{'selected': option.selected}" (click)="pickMatch(matchup,option);">
									<figure class="tile__image" style="background-image: url({{option.choice.choice_img}});">
										<a href="#" class="btn btn--alt tile__btn">Submit</a>
									</figure><!-- /.tile__image -->

									<h2 class="h4 tile__title" [innerHTML]='option.choice.choice_copy'></h2><!-- /.h4 tile__title -->

									<h2 class="h5 tile__subtitle" [innerHTML]='option.choice.choice_alt_text'></h2><!-- /.h5 tile__subtitle -->
								</a><!-- /.tile-alt -->
							</div><!-- /.grid__col grid__col-/-1of2 -->
					
						</div><!-- /.grid 
						
							<h5>Round is {{matchup.round}} - Match is {{matchup.match}} Current Q is {{current_q}}</h5>-->
					</div><!-- /.section__content -->
				</div><!-- /.section__body -->

					<div class="section__actions">
					<ul>
						<li>
							<a (click)="prevSlot()">
								<i class="ico-arrow-circle">
									<img src="assets/images/svg/btn-left.svg" alt="">
								</i>
							</a>
						</li>

						<li>
							<p>{{bracket.matches[current_q].round_order}} <span>/ {{bracket.matches[current_q].round_total}}</span></p> 
						</li>

						<li>
							<a (click)="nextSlot()">
								<i class="ico-arrow-circle">
									<img src="assets/images/svg/btn-right.svg" alt="">
								</i>
							</a>
						</li>
					</ul>
				</div><!-- /.section__actions -->
				
				</div>
					
				<div class="round_card" *ngIf="round_card_visible">
					<div class="section__body">
						<h2 class="h1 section__title" *ngIf="current_q==(bracket.matches.length-1)">Final Round</h2>
						<h2 class="h1 section__title" *ngIf="current_q<(bracket.matches.length-1)">Round {{bracket.matches[current_q].round}}</h2><!-- /.h3 section__title -->
						<!-- add in submit round-->
					</div><!-- /.section__body -->

					<div class="section__actions">
						
						<a (click)="goBack()" class="btn section__btn" *ngIf="current_q>1">Go Back</a>
						<a (click)="nextRound()" class="btn section__btn">Start Round</a>
						
					</div><!-- /.section__actions -->
				
				</div>
			</div>
		</div><!-- /.section__inner -->
	</div><!-- /.shell -->
</section><!-- /.section -->
