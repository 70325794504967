import { Component, Input, OnInit } from '@angular/core';
//Services
import { CampaignService } from '../../services/campaign.service';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
   @Input() CS: any | [];
   @Input() currentState: string;
   public campaign_landing: any;
   constructor(public CampSvc: CampaignService) { }


  ngOnInit(): void {
	  //this.CS.campaignInfo.subscribe();
	  //this.campaign_landing=this.CS.campaignInfo.value;
  }
	beginQuiz(){
		if(this.CS.quiz[0].content_id>0 && this.CS.quiz[0].content_source!=='' && this.CS.quiz[0].content_source!==null){
			this.CampSvc.currentState="video";
			console.log('content is', this.CS.quiz[0].content_id);
			this.CampSvc.playVideo(this.CS.quiz[0].content_id);
		}else{		
			this.CampSvc.launchQuestions(this.CS.quiz[0].content_id);
			console.log('clicked', this.CS.quiz);
		}
	}

}