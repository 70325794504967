import { Component, ElementRef, OnInit,Input, Renderer2, AfterViewInit, ViewChildren, QueryList,ChangeDetectorRef } from '@angular/core';
import { CampaignService } from '../../services/campaign.service';
import * as $ from 'jquery'; // Import jQuery if not already done
import 'jquery-ui-dist/jquery-ui';
interface Choice {
  nativeElement: HTMLElement;
  isSelected: boolean;
  isPlaced:boolean;
}

interface Placeholder {
  nativeElement: HTMLElement;
  clonedChoice: Choice | null;
  isAvailable: boolean;
  isDropped:boolean;
}
@Component({
  selector: 'app-matching',
  templateUrl: './matching.component.html',
  styleUrls: ['./matching.component.scss']
})
export class MatchingComponent implements OnInit {

  	@Input() CS: any | [];
	@Input() content:  any | [];
	@Input() currentState: string;
	@ViewChildren('boxChoices') boxChoicesRef: QueryList<ElementRef>;
  	@ViewChildren('boxAnswers') boxAnswersRef: QueryList<ElementRef>;
	@ViewChildren('.js-box-answer') placesRef: QueryList<ElementRef>;
	
	boxChoices: Choice[] = [];
    boxAnswers: Placeholder[] = [];
  	selectedChoice: Choice | null = null;
private jsBoxChoiceInitialized = false;
	currentScore=0;
	checkAvail=false;
	current_q=0;
	percent_score=0;
	current_choice_index='none';
	isDraggable = true; 
	isMobile: boolean;
	placing=false;
	//selectedChoice = null;
	

  constructor(public CampSvc: CampaignService, private renderer: Renderer2, private elementRef: ElementRef, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
	 this.checkIfMobile(); // Initial check

    // Listen for window resize events
    this.renderer.listen('window', 'resize', () => {
      this.checkIfMobile();
    });
	  console.log('contentbase is', this.content.questions);
	 
  }
  ngAfterViewInit(): void {
//	  this.initializeDraggable();
	if(this.isMobile){
		this.boxChoices = this.boxChoicesRef.map(item => ({
		  nativeElement: item.nativeElement,
		  isSelected: false,
		  isPlaced:false
		}));

		this.boxAnswers = this.boxAnswersRef.map(item => ({
		  nativeElement: item.nativeElement,
		  clonedChoice: null,
		  isAvailable:false,
		  isDropped:false
		}));
	}else{
		this.handleDragDrop();
	}
    
 }
	
  checkIfMobile() {
    // Determine if the screen width is below a certain threshold (e.g., 768px)
    this.isMobile = window.innerWidth <= 768;
  }
	
 selectChoice(event: MouseEvent) {
	 const target = event.currentTarget as HTMLElement;
	const selectedChoice = this.boxChoices.find(choice => choice.nativeElement === target);
	 
console.log('select', target, this.boxChoices);

    if (!selectedChoice){
	console.log('none');
      return; // If not found, do nothing
    }
    // Deselect any previously selected choice
    if (this.selectedChoice) {
    	this.selectedChoice.nativeElement.classList.remove('is-selected');
		this.selectedChoice.isSelected = false;
		console.log('unselect');
    }

    // Select the current choice
    selectedChoice.isSelected = true;
	selectedChoice.nativeElement.classList.add('is-selected');
	this.placing=true;	 
    this.selectedChoice = selectedChoice;
  }

 placeClone(event: MouseEvent) {
	 if(!this.selectedChoice){
		 //selecting a populated cell instead of placing
		const target = event.currentTarget as HTMLElement;
		
		console.log('select placed', target.getAttribute('data-pick-id'));
		if(Number(target.getAttribute('data-pick-id'))>0){
		 //this.selectedChoice=this.boxChoices[0];
		
		 	
	     	const clonetarget=target.querySelector('.is-clone') as HTMLElement
			const newChoice: Choice = {
			  nativeElement: clonetarget,
			  isSelected: false,
			  isPlaced: false, // You might want to set this based on your logic
			};
		 	 this.selectedChoice=newChoice;
		 	this.placing=true;
			 return;
		 }else{
			  console.log('select empty');
			 return;
		 }
		 
		 
	 }else{
		 //identify placement cell
		 console.log('placing');
	 	const target = event.currentTarget as HTMLElement;
	  	this.placing=false;
	  	this.selectedChoice.nativeElement.classList.remove('is-selected');

	   	if(this.selectedChoice) {
		   const dropElement=target;
		   const dragElement=this.selectedChoice.nativeElement;
		   //look for the original option tile for the tile to be placed
		   const optCellOrigin = this.boxChoices.find(choice => choice.nativeElement.getAttribute('id') === dropElement.getAttribute('data-pick-id'));

		  // console.log('optOrigin',optCellOrigin,dropElement.getAttribute('data-pick-id'), dropElement );
//		   console.log('matchorigin',matchCellOrigin,dragElement.getAttribute('data-answer-id'), dragElement );
		   
			if (dropElement.classList.contains('is-dropped') && !dragElement.classList.contains('is-clone')) {
			  	console.log('replacing populated with fresh', dropElement);
			  	optCellOrigin.nativeElement.classList.remove('is-placed');
			} else if (dragElement.classList.contains('is-clone') && !dropElement.classList.contains('is-dropped')) {
			  	console.log('replacing empty with placed elsewhere');
				this.replaceEmpty(dragElement.getAttribute('data-answer-id'));
			} else if (dragElement.classList.contains('is-clone') && dropElement.classList.contains('is-dropped')) {
			  console.log('replacing populated with placedelswehere', dropElement);
			  this.restoreOption(dropElement.getAttribute('data-pick-id'));
			 // optCellOrigin.nativeElement.classList.remove('is-placed');
			  this.replaceEmpty(dragElement.getAttribute('data-answer-id'));
			}
			   
		  // Clone the entire nativeElement as the choice
		  const clonedChoice = this.selectedChoice.nativeElement.cloneNode(true) as HTMLElement;
		   clonedChoice.classList.add('is-clone');
		   clonedChoice.removeAttribute('(click)');
		    clonedChoice.removeAttribute('ng-reflect-ng-for-of');
			 clonedChoice.removeAttribute('boxChoice');
		   
		  // Place the cloned choice in the selected placeholder
		  target.innerHTML = '';
		  target.appendChild(clonedChoice);
			target.classList.add('is-dropped'); 
		  
		  if(!dragElement.classList.contains('is-clone')){
			  this.selectedChoice.nativeElement.classList.add('is-placed');
		  }
		  target.setAttribute('data-pick-id', this.selectedChoice.nativeElement.getAttribute('data-answer-id'));
		  // Deselect the choice after placement
		  this.checkDataPickId();
		  this.selectedChoice.isSelected = false;
		  this.selectedChoice = null;

		}
	 }
 }
	replaceEmpty(id){
		console.log(id);
		//console.log(this.boxAnswersRef);
		this.boxAnswersRef.forEach(function(item: ElementRef<any>){
			if(item.nativeElement.getAttribute('data-pick-id')===id){
				const tempid='place'+item.nativeElement.getAttribute('data-match-id');
				item.nativeElement.classList.remove('is-dropped');
				item.nativeElement.innerHTML='<div id="'+ tempid +'" style="border: 1px solid #fff;border-radius: 6px;position: relative;min-height: 95px;"></div>';
				item.nativeElement.setAttribute('data-pick-id', '0');				
			}
		});
	}
	restoreOption(id){
		this.boxChoicesRef.forEach(function(item: ElementRef<any>){
			if(item.nativeElement.getAttribute('id')===id){
				item.nativeElement.classList.remove('is-placed');
			}
		});
	}

	
	
  checkDataPickId(): void {
	  console.log('start');var i=0;
	  var that=this;
	  var matches=this.content.questions[this.current_q].matches;
	  
    this.boxAnswersRef.forEach((boxAnswer: ElementRef) => {
		
      const dataPickId = boxAnswer.nativeElement.getAttribute('data-pick-id');
	  const dataMatchId = boxAnswer.nativeElement.getAttribute('data-match-id');
	  matches.forEach(function(obj2){
		  
		  if(obj2.correct_match==dataPickId){
			   if (dataPickId && parseInt(dataPickId, 10) > 0) {
					i++
					console.log(`data-pick-id is greater than 0 for element: ${boxAnswer.nativeElement.textContent}`);
				  }
				that.updateMatch(dataMatchId,dataPickId);
		  }  
	  });
     
	  
    });
	 
	  if(i==3){this.checkAvail=true;}else{this.checkAvail=false;}
	  this.cdr.detectChanges();
  }
	updateMatch(match,pick){
		this.content.questions[this.current_q].matches.forEach(function(obj){
		  if(obj.correct_match==match){
			 if(match==pick){
				 obj.correct=true;
			 }else{
				 obj.correct=false;
			 }
		  }
	  	});
	}
	submit_answer(question){
		if(question.answered){
			this.nextQ();
		}else{
		
	  var q_arr=this.content.questions[this.current_q];
		
	  q_arr.points = q_arr.matches.reduce((count, obj) => count + (obj.correct ? 1 : 0), 0);
	  q_arr.answered=true;
	  //update score 	  
	  this.updateScore();
			console.log('disable');
		this.isDraggable=false;
		const jsBoxChoiceElements = $('.js-box-choice');
		jsBoxChoiceElements.draggable('disable');
		  this.cdr.detectChanges();
	 // this.current_choice_index="none";//clears mobile selection
		}
//		this.toggleDraggable(false);
//		this.cdr.detectChanges();
		
		//this.toggleDraggable(false);
		

		// const jsBoxChoiceElements = $('.js-box-choice');
		//jsBoxChoiceElements.draggable('disable');
		
	}
	mobile_select(id){
		this.current_choice_index=id;
	  console.log('selected mobile'+id);
	
		
	}
	
	updateScore(){
	  var temp_score=0;
	  this.content.questions.forEach(function(obj){
		  if(!obj.points){obj.points=0;}
		  temp_score=temp_score+parseInt(obj.points);
	  });
	  this.currentScore=temp_score;
	  this.percent_score=(this.currentScore/this.CS.max_score)*100;
	  //console.log(this.currentScore + ' out of ' + this.CS.max_score+ ' is ' + this.percent_score);
  }
	
	nextQ(){
	  if((this.current_q+1) >=this.content.questions.length){
		this.CampSvc.score=this.currentScore;
		this.CampSvc.percentScore=this.percent_score;
		this.CampSvc.showScore('quiz');	
	  }else{
		  //console.log('currenta'+this.current_q);
	  	this.current_q=this.current_q+1;
		  this.checkAvail=false;
		 // console.log('currentb'+this.current_q);
	  } 
//		this.toggleDraggable(true);
		console.log('enable');
		this.isDraggable=true;
		const jsBoxChoiceElements = $('.js-box-choice');
		jsBoxChoiceElements.draggable('enable');
		  this.cdr.detectChanges();

		//this.toggleDraggable(true);
		// const jsBoxChoiceElements = $('.js-box-choice');
		//jsBoxChoiceElements.draggable('enable');
		//this.handleDragDrop();
		//console.log(jsBoxChoiceElements.draggable);
//		this.cdr.detectChanges();
  }

  private handleDragDrop(): void {
	
    const jsBoxChoice = $(this.elementRef.nativeElement).find('.js-box-choice');
    const jsBoxAnswer = $(this.elementRef.nativeElement).find('.js-box-answer');

    jsBoxChoice.draggable({
      revert: 'invalid',
      revertDuration: 200,
      zIndex: 100,
      snap: '.js-box-answer',
      snapTolerance: 10,
      snapMode: 'inner',
      cursor: 'move',
      helper: 'clone'
    });

    jsBoxChoice.droppable({
      accept: '.js-box-choice.is-clone',
      drop: (event, ui) => {
        const cloneElement = $(ui.draggable);
        const $this = $(this);
        const answerId = cloneElement.attr('data-answer-id');

        if (answerId != $this.attr('data-answer-id')) {
          return;
        }

        cloneElement
          .prev()
          .removeClass('is-hidden is-dropped');
        cloneElement.remove();

        $this.removeClass('is-dragged');
		  console.log('this0');
      }
    });

    jsBoxAnswer.droppable({
      accept: '.js-box-choice',
      drop: (event, ui) => {
        const dropElement = $(event.target);
        const dragElement = $(ui.draggable);

        if (dropElement.hasClass('is-dropped') && !dragElement.hasClass('is-clone')) {
          const currentAnswer = dropElement.next();
          const optCellOrigin = $(`.js-box-choice[data-answer-id=${currentAnswer.attr('data-answer-id')}]`);
          optCellOrigin.removeClass('is-dragged');
          currentAnswer.remove();
        } else if (dragElement.hasClass('is-clone') && !dropElement.hasClass('is-dropped')) {
          dragElement.prev().removeClass('is-hidden is-dropped');
          dragElement.remove();
		  const currentElement = dropElement.next();
		  const containerOfDropElement = currentElement.parent();
        } else if (dragElement.hasClass('is-clone') && dropElement.hasClass('is-dropped')) {
          const currentElement = dropElement.next();
          const dragQuestionId = dragElement.attr('data-answer-id');
          const dropQuestionId = currentElement.attr('data-answer-id');
          const containerOfDragElement = dragElement.parent();
          const containerOfDropElement = currentElement.parent();

          const cloneDropElement = currentElement.clone();
          const cloneDragElement = dragElement.clone();
          const prevBox = dragElement.prevAll('.js-box-answer').first();
	    //  const prevbox=containerOfDragElement.find('js-box-answer').attr('id');
		   prevBox.attr('data-pick-id', dropQuestionId);
	   	 // cloneDragElement.attr('data-pick-id', dropQuestionId);
          //cloneDragElement.attr('data-pick-id', dragQuestionId);
	     //	console.log('pre',dragElement.attr('data-answer-id'));
			dragElement.replaceWith(cloneDropElement);
          	currentElement.replaceWith(cloneDragElement);
          	cloneDropElement.removeClass('ui-droppable-hover');
        //	console.log('post',dragElement.attr('data-answer-id'));
			 dropElement.attr('data-pick-id', dragElement.attr('data-answer-id'));
			this.checkDataPickId();
			this.handleDragDrop();

          return;
        }

        const questionId = dropElement.attr('data-question-id');

        const cloneElement = dragElement.clone();
        cloneElement.attr('data-question-id', questionId).addClass('is-clone');

        dragElement.addClass('is-dragged');
        dropElement
          .addClass('is-hidden')
          .parent()
          .append(cloneElement);

        this.handleDragDrop();

        dropElement.addClass('is-dropped');
        dragElement.addClass('is-dragged');
		
	    dropElement.attr('data-pick-id', dragElement.attr('data-answer-id'));
		this.checkDataPickId();
      }
    });
	  
  }
	onDragStart(event: DragEvent): void {
	  if (!this.isDraggable) {
		// Prevent dragging if the condition is not met
		event.preventDefault();
	  }
	}
	// Function to enable or disable dragging based on a condition
toggleDraggable(enabled: boolean): void {
  this.isDraggable = enabled;
  const jsBoxChoiceElements = $('.js-box-choice');

  if (enabled) {
    // Enable draggable
    jsBoxChoiceElements.draggable('enable');
  } else {
    // Disable draggable
    jsBoxChoiceElements.draggable('disable');
  }
}

}
