import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SortByPipe } from './orderby.pipe';
import { MultiFilterPipe } from './multi-filter.pipe';



//Components
import { ContainerComponent } from './containers/container.component';
import { LandingComponent } from './components/landing/landing.component';
import { PopupComponent } from './components/landing/popup.component';
import { VideoComponent } from './components/landing/video.component';
import { BaseQuizComponent } from './components/quiz/base-quiz.component';
import { ScoreDefaultComponent } from './components/score-default/score-default.component';
import { ScoreSweepsComponent } from './components/score-sweeps/score-sweeps.component';
import { ThanksComponent } from './components/thanks/thanks.component';
import { PrizingComponent } from './components/landing/prizing.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { AppScriptsComponent } from './components/app-scripts/app-scripts.component';
import { PartialHeaderComponent } from './components/partial-header/partial-header.component';
import { FooterScriptsComponent } from './components/footer-scripts/footer-scripts.component';
import { FooterComponent } from './components/footer/footer.component';
import { TermsComponent } from './components/articles/terms.component';
import { PrivacyComponent } from './components/articles/privacy.component';
import { HthComponent } from './components/quiz/hth.component';
import { BracketComponent } from './components/quiz/bracket.component';
import { PersonalityComponent } from './components/quiz/personality.component';
import { SafeHtmlPipe} from './safeHtml.pipe';
import { CampaignAdminComponent } from './components/admin/campaign-admin.component';
import { CampaignEntryComponent } from './components/admin/campaign-entry.component';

//Services etc
import { CampaignService } from './services/campaign.service';
import { ContentManagementService } from './services/content-management.service';
import { S3UploadService } from './services/s3upload.service';
import { MatchingComponent } from './components/quiz/matching.component';

//import { AppStoreModule } from './store/app-store.module';
//import { todos } from './redux/reducer';


@NgModule({
  declarations: [
    AppComponent,
	ContainerComponent,
    LandingComponent,
	PopupComponent,
	VideoComponent,
    BaseQuizComponent,
    ScoreDefaultComponent,
    ScoreSweepsComponent,
    ThanksComponent,
    PrizingComponent,
    AppHeaderComponent,
    AppScriptsComponent,
    PartialHeaderComponent,
    FooterScriptsComponent,
    FooterComponent,
    HthComponent,
    BracketComponent,
    PersonalityComponent,
	  SortByPipe,
	  MultiFilterPipe,
	  TermsComponent,
	  PrivacyComponent,
	  SafeHtmlPipe,
	  CampaignEntryComponent,
	  CampaignAdminComponent,
	  MatchingComponent
  ],
  imports: [
    BrowserModule,
	FormsModule,
    HttpClientModule,
    AppRoutingModule

  ],
  providers: [CampaignService, ContentManagementService, CookieService,S3UploadService],
  bootstrap: [AppComponent]
})
export class AppModule { }
