<section class="section-dev section-personality">
	<div class="shell">
		<div class="section__inner">
			<h1 class="h3 section__title">Answer the questions below. Don’t overthink it—choose the first answer that comes to mind!</h1><!-- /.h3 section__title -->

			<div class="section__body">
				<div class="question" *ngFor="let question of content.questions; let i=index" id="q{{question.question_id}}">
					<div class="question__head question__head--alt">
						<h2 class="h6 question__title">QUESTION {{(i +1)}}</h2><!-- /.h6 question__title -->

						<h2 class="h4 question__subtitle">{{question.question_copy}}</h2><!-- /.h4 question__subtitle -->
					</div><!-- /.question__head -->
						
					<div class="question__body">
						<div class="grid grid--alt">
							<div class="grid__col grid__col--1of4" *ngFor="let choice of question.choices">
								<div class="tile" (click)="selectChoice(question,choice);">
									<span class="tile__label" >Tap to Confirm</span>

									<div class="tile__body">
										<p [innerHTML]="choice.choice_copy"></p>
										<div class="tile__image" style="background-image: url({{choice.choice_img}});"></div><!-- /.tile__image -->
									</div><!-- /.tile__body -->

									<div class="tile__radio">
										<input type="radio" data-target="#scroll-target" id="question{{question.question_id}}-{{choice.choice_id}}" name="question{{question.question_id}}" 
											   value="{{choice.category}}" [checked]="choice.selected">

										<label for="question{{question.question_id}}-{{choice.choice_id}}">
											<i class="ico-check">
												<img src="assets/images/svg/check.svg" alt="">
											</i>
										</label>	
									</div><!-- /.tile__radio -->
								</div><!-- /.tile -->
							</div><!-- /.grid__col grid__col-/-1of4 -->
						</div><!-- /.grid -->
					</div><!-- /.question__body -->
				</div><!-- /.question -->
			</div><!-- /.section__body -->

			<div class="section__foot">
				<div class="section__actions">
					<a (click)="submitQuiz();" class="btn" id="submit_btn" [ngClass]="{'btn--disabled': !submittable}" >Submit</a>
				</div><!-- /.section__actions -->

				<p *ngIf="!submittable">Please make a selection for each question before submitting.</p>
			</div><!-- /.section__foot -->
		</div><!-- /.section__inner -->
	</div><!-- /.shell -->
</section><!-- /.section -->

