<section class="section quiz section-base-quiz" >
		<div class="shell">
			<div class="questions" 
				 *ngFor="let question of content.questions; let i=index" id="q{{question.question_id}}"  [ngClass]="{'active': i==current_q, 'inactive':i!==current_q }" >
				
				<div class="section__inner question" id="Q{{i}}"  
					 [ngClass]="{'active': question.answered == 0,'inactive': question.answered !== 0 }" >
					<div class="section__head">
						<h1 class="h5">Question {{(i +1)}}</h1><!-- /.h5 -->

						<div class="section__head-list">
							<ul class="list-dev">
								<li *ngFor="let x of [].constructor(content.questions?.length) let k=index" [ngClass]="{'current':k<=i }"  style="width:calc(100% / {{content.questions?.length}})"></li>

							</ul><!-- /.list-dev -->
						</div><!-- /.section__head-list -->

						<figure style="background-image: url({{question.question_img}});"></figure>

						<span [innerHTML]="question.question_copy | safeHtml"></span>
					</div><!-- /.section__head -->

					<div class="section__body">
						<div class="grid choices">
							
							<div class="grid__col grid__col--1of2" *ngFor="let choice of question.choices; let j=index">
								<div class="radio">
									<input type="radio" class="choice__desktop" id="question{{question.question_id}}-{{choice.choice_id}}-desktop" name="question{{question.question_id}}" value="{{choice.points}}" (click)="submit_answer(j);" >
									
									
									<input type="radio" class="choice__mobile" id="question{{question.question_id}}-{{choice.choice_id}}-mobile" name="question{{question.question_id}}" value="{{choice.points}}" (click)="mobile_select(j);" >
									<!--data-target="#scroll-target-{{question.question_id}}"-->

									<label for="question{{question.question_id}}-{{choice.choice_id}}-desktop" class="choice__desktop">{{choice.choice_copy}}</label>
									<label for="question{{question.question_id}}-{{choice.choice_id}}-mobile" class="choice__mobile">{{choice.choice_copy}}</label>	
								</div><!-- /.radio -->
							</div><!-- /.grid__col grid__col-/-1of2 -->

						</div><!-- /.grid -->
					</div><!-- /.section__body -->

					<div class="section__actions" id="scroll-target-{{question.question_id}}" *ngIf="current_choice_index!=='none'">
						<a class="btn btn--red section__btn"  (click)="submit_answer(current_choice_index);" >Submit Answer</a>
					</div><!-- /.section__actions -->

					<div class="section__foot">
						<ul class="list-dev">
							<li *ngFor="let x of [].constructor(content.questions?.length) let k=index" [ngClass]="{'current':k<=i }" style="width:calc(100% / {{content.questions?.length}})"></li>
							<!--
								style="width:calc(100% / {{content.questions?.length}})
								<!--
								[ngStyle]="{'width': 100/content.questions?.length+ '%','max-width': 100/content.questions?.length+ '%'}"-->
						</ul><!-- /.list-dev -->
					</div><!-- /.section__foot -->
				</div><!-- /.section__inner -->
				
				<div class="section__inner answer inactive" [ngClass]="{'active': question.answered == 1, 'inactive': question.answered !== 1} ">
					<div class="section__head section__head--alt">
						<h1 class="h5">Question {{(i +1)}}</h1><!-- /.h5 -->

						<div class="section__head-list">
							<ul class="list-dev">
								<li *ngFor="let x of [].constructor(content.questions?.length) let k=index" [ngClass]="{'current':k<=i }" style="width:calc(100% / {{content.questions?.length}})"></li>
							</ul><!-- /.list-dev -->
						</div><!-- /.section__head-list -->

						<figure style="background-image: url({{question.answer_img}});"></figure>
					</div><!-- /.section__head -->

					<div class="section__body section__body--alt">
						<p>That answer is</p>
										
						<h2 class="h1">{{question.result}}</h2><!-- /.h1 -->

						<p>{{question.answer_copy}}</p>
						
						<p class="answer__detail" [innerHTML]="question.answer_detail"></p>
						<div class="section__button">
							<a  (click)="nextQ();" class="btn" analytics-on="click" analytics-event='nextq'>

								Continue
							</a>
						</div><!-- /.section__button -->
					</div><!-- /.section__body -->

					<div class="section__foot">
						<ul class="list-dev">
							<li *ngFor="let x of [].constructor(content.questions?.length) let k=index" [ngClass]="{'current':k<=i }" style="width:calc(100% / {{content.questions?.length}})"></li>
						</ul><!-- /.list-dev -->
					</div><!-- /.section__foot -->
				</div><!-- /.section__inner -->

			</div>
		</div><!-- /.shell -->
	</section><!-- /.section quiz-->

