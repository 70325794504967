<div class="wrapper">
	<router-outlet></router-outlet>
<!--	<app-popup [CS] = "campaign$"></app-popup>
	<div class="wrapper__background" style="background-image: url({{campaign$.campaign_header_image}});" [ngClass]="{'wrapper__background--dev': campaign$.themed }"></div>
	<app-partial-header></app-partial-header>
	<div class="container" ng-controller="mainController">
		<main class="main__container" >
			<div class="main" id="landing">
				<app-landing [CS] = "campaign$" [currentState]="currentState$" class="stage" [ngClass]="{'currentstage' : CampSvc.currentState=='landing'}" *ngIf=" CampSvc.currentState=='landing'"></app-landing>
				<div  class="stage" *ngFor="let quiz of campaign$.quiz" [ngClass]="{'currentstage' : CampSvc.currentState=='quiz' || CampSvc.currentState=='video' }" >
					<app-video-content [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="quiz.content_source!==null && quiz.content_source!==''" [ngClass]="{'not_current' : CampSvc.currentState=='quiz'}"></app-video-content >
					<app-base-quiz  [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="campaign$.format_id==1"></app-base-quiz>
					<app-bracket [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="campaign$.format_id==2"></app-bracket>
					<app-hth  [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="campaign$.format_id==2"></app-hth>
					<app-personality  [CS] = "campaign$" [currentState]="currentState$" [content]="quiz" *ngIf="campaign$.format_id==4"></app-personality>
				</div>
				<app-score-default [CS] = "campaign$" [currentState]="currentState$" ></app-score-default>
				<app-score-sweeps [CS] = "campaign$" [currentState]="currentState$"></app-score-sweeps>
				<app-thanks [CS] = "campaign$" [currentState]="currentState$"></app-thanks>
				State is: {{CampSvc.currentState}}
				<app-footer ></app-footer>
			</div>
		</main>
	</div>-->
</div>

