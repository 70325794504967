import { Component, Input, OnInit } from '@angular/core';
//Services
import { CampaignService } from '../../services/campaign.service';


@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html'
})
export class PopupComponent implements OnInit {
   @Input() CS: any | [];
   @Input() currentState: string;
   public campaign_landing: any;
   constructor(public CampSvc: CampaignService) { }


  ngOnInit(): void {
	  //this.CS.campaignInfo.subscribe();
	  //this.campaign_landing=this.CS.campaignInfo.value;
  }
}